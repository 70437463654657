import React from 'react'
import { Search } from '.'

const SearchContainer = props => {
  return (
    <div
      className={`${
        props.isActive ? 'animate-height-down' : 'animate-height-up'
      }`}
    >
      <div className={`${props.isActive ? 'slide-down' : 'slide-up'} `}>
        <Search placeholder="Search our sites" isActive={props.isActive} />
      </div>
    </div>
  )
}

export default SearchContainer
