import React from 'react'
import NewsCard from './NewsCard'
import { YouTubeEntry } from 'components/sites/'
import moment from 'moment'

const NewsDeck = props => {
  const { newsItems, siteNews } = props
  let isNewsShown = false

  const checkIsNewsShown = (articleType, diff) => {
    if (articleType === 'article' && diff < 0) {
      isNewsShown = true
    }
  }

  return (
    <div data-cy="latest" className="md:flex md:flex-wrap md:-mx-4">
      {newsItems?.length &&
        newsItems.map(item => {
          let now = moment(new Date())
          let dateObj = moment(new Date(item?.fields?.publishAt))
          const dateDiff = dateObj.diff(now)
          const key = item.sys.id + Math.random()
          checkIsNewsShown(item?.sys?.contentType?.sys?.id, dateDiff)
          
          return item?.sys?.contentType?.sys?.id === 'article' ? (
            dateDiff < 0 && (
              <div
                className="card md:w-1/3 lg:w-1/4 md:px-4 md:mb-8"
                key={key}
              >
                <NewsCard data={item} siteNews={siteNews} />
              </div>
            )
          ) : (
            <div
              className="card md:w-1/3 lg:w-1/4 md:px-4 md:mb-8"
              key={key}
            >
              {item?.sys?.contentType?.sys?.id === 'youTubeEmbed' && (
                <YouTubeEntry
                  key={key}
                  fields={item?.fields}
                  sys={item?.sys}
                />
              )}
            </div>
          )
        })}
      {newsItems?.length &&
        isNewsShown === false && (
          <div className="flex items-center justify-center ml-4 my-1 text-lg font-semibold text-primary">
            No news found
          </div>
        )}
    </div>
  )
}

export default NewsDeck
