import { accessGroup } from './config'

export const isInGroup = (userGroups, portal = 'commercial') => {
  let isFind = false
  userGroups.forEach(userGroup => {
    if ((accessGroup[portal] || []).indexOf(userGroup.split('@')[0]) > -1)
      isFind = true
  })
  return isFind
}
