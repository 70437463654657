import React from 'react'
import { YouTubeEntry } from './'

const YouTubeSection = ({ fields }) => {
  return fields ? (
    <section className="mt-10 mb-10">
      <h2 className="mb-6 text-lg font-semibold md:text-xl">{fields.title}</h2>
      <div className="flex flex-wrap -mx-4">
        {(fields.youtubeEntry || []).map(entry => {
          return (
            <div className="card md:w-1/3 lg:w-1/4 md:px-4 md:mb-8"  key={entry?.sys?.id}>
              <YouTubeEntry
                key={entry?.sys?.id}
                fields={entry?.fields}
                sys={entry?.sys}
              />
            </div>
          )
        })}
        {/* Flex killers below */}
        <div className="hidden mx-2 md:flex youtube" />
        <div className="hidden mx-2 md:flex youtube" />
      </div>
    </section>
  ) : null
}

export default YouTubeSection
