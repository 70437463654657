import React from 'react'

const MenuList = props => {
  return (
    <div className="list-reset -mx-4 border-b border-grey">
      {props.children}
    </div>
  )
}

export default MenuList
