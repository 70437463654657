import React, { useState } from 'react'
import Icon from './utils/Icon'
import config from 'config'
import Cookies from 'js-cookie'
import moment from 'moment'

const TwoSV = () => {
  const [show, setShow] = useState(true)

  const handleClick = response => {
    const expirationDate =
      response === 'ok'
        ? moment()
            .add(10, 'y')
            .toDate()
        : moment()
            .add(2, 'd')
            .toDate()
    Cookies.set('show2SVNotification', 'no', { expires: expirationDate })
    setShow(false)
  }

  const show2SVNotification = Cookies.get('show2SVNotification')
  if (show2SVNotification) return null
  if (!show2SVNotification && show) {
    return (
      <div
        className="fixed pin p-4 flex items-center justify-center bg-black-transparent z-50"
        data-cy="2sv-banner"
      >
        <div className="container mx-auto max-w-sm">
          <div className="rounded-lg overflow-hidden shadow-lg">
            <div className="bg-green p-4 text-center flex items-center justify-center">
              <Icon
                icon="info"
                size="28"
                classStyle="cursor-pointer relative"
                color="text-orange"
              />
              <span className="text-white inline-block ml-2">
                Important information
              </span>
            </div>
            <div className="bg-white shadow-md">
              <div className="flex items-center pt-4 mx-4">
                <div className="text-lg text-center py-2 rounded-lg">
                  We're introducing 2 step verification to help protect your
                  account. Please set this up now.
                </div>
              </div>
              <div className="flex -mx-2 p-4">
                <a
                  href={config.twoStepLink}
                  onClick={() => handleClick('ok')}
                  className="mx-2 text-black cursor-pointer active:text-black no-underline text-center w-full bg-orange hover:bg-orange-dark p-4 px-8 font-semibold rounded rounded-md inline-block"
                >
                  Setup now
                </a>

                <a
                  onClick={() => handleClick('dismiss')}
                  className="mx-2 text-black cursor-pointer active:text-black no-underline text-center w-full bg-grey hover:bg-grey-dark p-4 px-8 font-semibold rounded rounded-md inline-block"
                >
                  Snooze
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default TwoSV
