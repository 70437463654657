import React from 'react'

const LeftArrow = props => {
  return (
    <button
      className={props.classes}
      onClick={() => {
        props.handleClick('left')
      }}
    >
      <svg
        className="fill-green-light hover:fill-green"
        viewBox="0 0 9 14"
        width={props.width}
        height={props.height}
      >
        <path
          className="st0"
          d="M7.3,14L0,7l7.3-7L9,1.6L3.4,7L9,12.4L7.3,14z"
        />
      </svg>
    </button>
  )
}

export default LeftArrow
