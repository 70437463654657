import React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { ParagraphFaq, LinkPage, HighlightText } from '.'
import MuiAccordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import { styled } from '@mui/material/styles'

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: "0px solid ",
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));

const SearchFaqResult = props => {
  const { fields, faqContent } = props
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return <ParagraphFaq>{children}</ParagraphFaq>
      },
    },
  }

  return fields ? (
    <Accordion className="mb-2">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: '#00683a' }}/>}
          aria-controls="panel1a-content"
          className="flex items-center px-4 bg-secondary-lightest lg:py-1 lg:text-lg text-body"
          id={props.faqId}
        >
          <HighlightText highlightChildren={fields.title} />
        </AccordionSummary>
        <AccordionDetails className="p-4 c-accordion-panel lg:text-lg lg:p-8">
        {documentToReactComponents(fields.body, options)}
        <LinkPage content={faqContent} />
        </AccordionDetails>
       </Accordion>
  ) : null
}

export default SearchFaqResult
