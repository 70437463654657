import React from 'react'
import { Link } from 'react-router-dom'
import { DateWidget } from 'components/utils'
import { useIsMobile } from '../../util'

const NewsCard = props => {
  const [isMobile] = useIsMobile()
  const scaleImage = isMobile
    ? props.data?.fields?.imageTeaser?.fields?.file?.url +
      '?fit=thumb&w=500&h=282'
    : props.data?.fields?.imageTeaser?.fields?.file?.url +
      '?fit=thumb&w=250&h=141'
  return (
    <Link
      className="text-black no-underline"
      to={`/news/${props.data?.sys?.id}`}
    >
      <article
        className={`h-full handheld:flex handheld:py-2 
        handheld:border-solid handheld:border-b handheld:border-ligher-grey  cursor-pointer`}
      >
        <div className="flex-none w-32 h-auto md:w-full">
          <div className="relative overflow-hidden rounded-sm aspect-ratio-16/9 lg:rounded">
            <img
              src={scaleImage}
              alt="newscard"
              className="absolute block object-cover h-full min-w-full pin"
            />
          </div>
        </div>
        <div className="flex-1 handheld:ml-4 md:pt-6">
          <h3 className="mb-1 font-medium text-1xl xl:text-xl md:mb-4">
            {props.data?.fields?.title}
          </h3>
          <DateWidget
            className="block text-sm font-medium text-green-light md:mb-2"
            format="MMMM Do YYYY"
            value={props.data?.fields?.publishAt}
          />
          <p className="hidden mb-0 text-base leading-normal text-md md:block">
            {props.data?.fields?.summary}
          </p>
        </div>
      </article>
    </Link>
  )
}

export default NewsCard
