import React from 'react'
import Moment from 'react-moment'
import moment from 'moment'

const DateWidget = props => {
  let formatDate = dateVal => {
    let now = moment(new Date())
    let dateObj = new Date(dateVal);
    let duration = moment.duration(now.diff(moment(dateObj))).asDays()
    moment.updateLocale('en', {
      relativeTime: {
        m: '1 minute',
        h: '1 hour',
        d: '1 day',
      },
    })
    return duration > 7
      ? moment(dateObj).format(props.format)
      : moment(dateObj).fromNow()
  }

  return (
    <span className={props.className}>
      <Moment filter={formatDate}>{props.value}</Moment>
    </span>
  )
}

export default DateWidget
