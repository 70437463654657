import React, { useState, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import NewsHeroCategory from './NewsHeroCategory'
import { Container, Section } from 'components/utils'
import { contentfulClient } from '../../util'
import PropTypes from 'prop-types'

const LatestCategory = props => {
  const [newsList, setNewList] = useState(null)
  const { category } = props
  const getLatestNewsContent = () => {
    const query = {
      skip: 0,
      limit: 1,
      order: '-sys.createdAt',
      content_type: 'article',
      'fields.featured': true,
      'fields.category.sys.id[in]': category,
    }

    return contentfulClient.getContentfulClient().getEntries(query)
  }

  const { data: newsListContent } = useQuery({
    queryKey: ['pageContentSubCategory'],
    queryFn: getLatestNewsContent,
    staleTime: 60 * 60 * 1000,
    }
  )

  useEffect(
    () => {
      newsListContent && setNewList(newsListContent.items)
    },
    [newsListContent]
  )

  return (
    <Container hero>
      <Section title="Morrisons news" classes="hidden md:block lg:hidden">
        {newsList?.length > 0 &&
          newsList.map((news, index) => (
            <NewsHeroCategory key={index} data={news} />
          ))}
      </Section>
    </Container>
  )
}
LatestCategory.propTypes = {
  category: PropTypes.string,
}

export default LatestCategory
