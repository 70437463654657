import React, { useState } from 'react';

import {RichTextSection} from './';

const Bulletin = ({ bulletin, last, userLocation}) => {
  const [openLocations, setOpenLocations] = useState(false);

  const locationString = openLocations
    ? <span><span className="font-bold">{userLocation}</span>, {bulletin.fields.location.filter(l => l !== userLocation).join(", ")}</span>
    : <span className="italic">Click to expand</span>;

  return <div className={`overflow-hidden rounded mx-1 bg-white ${last ? "mb-1" : "mb-6"} shadow`}>
    <div className="px-3 pt-4">
      <RichTextSection richText={bulletin.fields.body} />
    </div>
    <div
      className={`text-sm px-3 py-1 cursor-pointer border-t border-grey-lighter transition duration-400 ${openLocations ? "text-grey-darkest" : "text-grey"}`}
      onClick={() => setOpenLocations(!openLocations)}
    >
      Applicable stores: {locationString}
    </div>
  </div>
}

export default Bulletin
