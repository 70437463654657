import React, { useState, useEffect } from 'react'
import { usePageState } from 'store/page-context'
import PageMenuItem from './PageMenuItem'

const PageMenu = ({ activePage, data }) => {
  const { path } = usePageState()
  const [currentActive, setCurrentActive] = useState()

  const setCurrentMenu = activeSlug => {
    setCurrentActive(activeSlug)
  }

  useEffect(
    () => {
      setCurrentActive(null)
    },
    [path]
  )

  return (
    <div className="sticky z-20 bg-white shadow-md pin-t">
      <div className="container flex mx-auto md:px-8">
        <div className="flex w-full whitespace-no-wrap list-reset flex-nowrap handheld:scrolling-touch handheld:overflow-x-auto md:overflow-x-hidden">
          <PageMenuItem
            item="home"
            activePage={activePage}
            isOpen={false}
            setCurrentMenu={setCurrentMenu}
          />
          {Array.isArray(data)
            ? data.map((item, index) => (
                <PageMenuItem
                  item={item}
                  key={index}
                  activePage={activePage}
                  isOpen={currentActive === item?.href?.slug}
                  setCurrentMenu={setCurrentMenu}
                />
              ))
            : null}
        </div>
      </div>
    </div>
  )
}

export default PageMenu
