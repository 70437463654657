import React from 'react'
import { Icon } from 'components/utils'

const MenuItem = props => {
  return (
    <button
      className="p-4 border-t border-grey flex items-center w-full"
      onClick={props.onClick}
      data-test={props.text}
    >
      <span className="font-thin no-underline">{props.text}</span>
      <Icon
        icon={props.icon}
        size="24"
        classStyle="ml-auto fill-current text-grey"
      />
    </button>
  )
}

export default MenuItem
