import React from 'react'
import { Link } from 'react-router-dom'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

const ActiveArrow = () => {
  return (
    <ChevronRightIcon
      size="small"
      sx={{ color: '#00683a', fontSize: '18px' }}
    />
  )
}

const MenuItem = ({ menuItem, hasSubPage }) => {
  const showArrow = Array.isArray(hasSubPage) && hasSubPage[0] ? true : false
  return (
    menuItem &&
    menuItem.href && (
      <div className="flex items-center">
        <Link
          data-id={menuItem.id}
          className="px-6 py-4 no-underline"
          style={{ color: 'currentColor' }}
          to={menuItem.href.url}
        >
          {menuItem.title}
        </Link>
        {showArrow && (
          <div className="absolute flex items-center px-2 mr-2 pin-r">
            <ActiveArrow />
          </div>
        )}
      </div>
    )
  )
}

const ReceiveMenu = ({ menuItems }) => {
  const [hideMenu, setHideMenu] = React.useState(true)
  const subMenuStyle = { top: '0', left: '100%' }

  const handleMouseEnter = e => {
    setHideMenu(false)
  }
  const handleMouseLeave = e => {
    setHideMenu(true)
  }

  return (
    <>
      {menuItems && menuItems.children ? (
        <div
          className="relative flex hover:text-primary-dark hover:bg-secondary-lighter"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <MenuItem menuItem={menuItems} hasSubPage={menuItems.children} />
          <div
            className={`${hideMenu ? 'hidden' : 'block'} absolute bg-white`}
            style={subMenuStyle}
          >
            <div
              className="shadow-md hover:text-primary-dark"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {(menuItems.children || []).map((menuSubItems, key) => {
                return <ReceiveMenu menuItems={menuSubItems} key={key} />
              })}
            </div>
          </div>
        </div>
      ) : (
        menuItems && (
          <MenuItem menuItem={menuItems} hasSubPage={menuItems.children} />
        )
      )}
    </>
  )
}

const FlyMenu = ({ linkList }) => {
  return (
    <div className="cursor-pointer">
      {linkList &&
        linkList.map((link, key) => {
          return (
            <div
              key={key}
              className="relative text-black hover:text-primary-dark hover:bg-secondary-lighter"
            >
              <ReceiveMenu menuItems={link} firstLevel={true} />
            </div>
          )
        })}
    </div>
  )
}

export default FlyMenu
