import React, { useState } from 'react'
import moment from 'moment'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { RichTextSection } from './'

const NoticeSection = ({ fields, open }) => {
  const [openNotice, setOpenNotice] = useState(open)

  const handleClick = () => {
    this.setOpenNotice({ openNotice: !openNotice })
  }

  let className = 'notice'

  if (fields.priority) {
    className += ` notice-${fields.priority}`
  }

  if (openNotice) {
    className += ` notice-open`
  }

  return (
    <section className={className}>
      <h2
        className="text-lg font-semibold md:text-xl notice-title"
        onClick={handleClick}
      >
        {fields.title}
        <ExpandMoreIcon size="small" sx={{ color: '#00683a' }} />
      </h2>
      <div className="notice-owner-label">Owner</div>
      <div className="notice-owner-content">{fields.owner}</div>
      <div className="notice-background-label">Background</div>
      <div className="notice-background-content">
        <RichTextSection richText={fields.background} />
      </div>
      <div className="notice-action-label">Action</div>
      <div className="notice-completedBy-label">Completed By</div>
      <div className="notice-completed-label">✓</div>
      <div className="notice-action-content">
        <RichTextSection richText={fields.action} />
      </div>
      <div className="notice-completedBy-content">
        {moment(fields.actionCompletionDate).format('dddd Do MMMM')}
      </div>
      <div className="notice-completed-content" />
      <div className="notice-contact-label">Key Contact</div>
      <div className="notice-contact-content">{fields.contact}</div>
    </section>
  )
}

export default NoticeSection
