import React from 'react'

const CircularProgress = props => {
  return (
    <div className="relative inline-block spinner">
      <svg className="circular">
        <circle
          className="path"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
      </svg>
    </div>
  )
}

export default CircularProgress
