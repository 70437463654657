const theme = {
  palette: {
    primary: {
      light: '#00893F',
      main: '#004e37',
      dark: '#1f9d55',
      contrastText: '#fff',
    },
  },
}

export default theme
