import React from 'react'
import Icon from 'components/utils/Icon'

const SearchIcon = props => {
  return (
    <Icon
      icon="search"
      size={props.size}
      classStyle={props.classes}
      color={props.color}
    />
  )
}

export default SearchIcon
