import React, { useState, useEffect } from 'react'

import Card from './ArchiveSection/Card'
import { LinerProgressBar } from 'components/sites/LinerProgressBar'
import { contentfulClient, Core } from '../../util'

export default function Archive({ onClick }) {
  const [loading, setLoading] = useState(false);
  const [weeks, setWeeks] = useState([]);

  useEffect(
    () => {
      setLoading(true)

      const weekBeforeLast = Core.getWeekDates(new Date(), -2);
      const yearAgo = Core.getWeekDates(new Date(), -52);
      console.log('Date filter archive :',yearAgo.start, weekBeforeLast.end)

      contentfulClient
        .getConvenienceContentfulClient()
        .getEntries({
          content_type: 'page',
          'fields.weekCommencing[gte]': yearAgo.start,
          'fields.weekCommencing[lte]': weekBeforeLast.end,
          order: '-fields.weekCommencing',
          select: 'fields.weekCommencing'
        })
        .then(entries => {
          setWeeks(entries);
          console.log("entries : ", entries)
          const weeks = entries.items.map(page => ({
            weekDate: Core.getWeekDates(page.fields.weekCommencing).weekDate,
            weekNum: Core.getWeekNumber(page.fields.weekCommencing)
          }));
          setWeeks(weeks);
          setLoading(false)
        })
        .catch(error => {
          setLoading(false)
          console.log(error)
        })
    },
    []
  )

  if (weeks.length === 0) {
    if (loading) {
      return <LinerProgressBar />
    }
    return <section className="container flex flex-wrap mx-auto mt-4 mb-10 md:px-6 px-2">Archive is currently empty</section>;
  }

  return (
    <section className="container flex flex-wrap mx-auto mt-4 mb-10 md:px-6 px-2">
      {weeks.length > 0 &&
        weeks.map(({weekDate, weekNum}, index) => <Card
          key={index}
          weekCommencing={weekDate}
          title={`Week ${weekNum}`}
          onClickFunction={() => onClick(weekDate)}
        />
        )
      }
      {loading && <LinerProgressBar />}
    </section>
  )
}
