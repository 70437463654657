import React, { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import NewsCategory from './NewsCategory'
import Latest from './Latest'
import LatestCategory from './LatestCategory'
import NewsList from './NewsList'
import { contentfulClient } from '../../util'
import smoothscroll from 'smoothscroll-polyfill'
import { LinerProgressBar } from 'components/sites/LinerProgressBar'
import { useSetState } from '../../util'
import { useNewsState, useNewsDispatch } from 'store/news-context'
import { ReactSwipe } from './ReactSwipe'

smoothscroll.polyfill()

const consoleError = console.error

console.error = function filterErrors(msg, ...args) {
    if (
        /UNSAFE_componentWillReceiveProps/.test(msg) &&
        args[0] === 'ReactSwipableView'
    ) {
        return
    }
    consoleError(msg, ...args)
}

const NewsContainer = () => {
    const defaultState = {
        newsList: [],
        menuGroups: [],
        errMessage: '',
    }

    const [state, setState] = useSetState(defaultState)
    const { setSelectedCategoryIndex, saveContentCategories } = useNewsDispatch()
    const newsState = useNewsState()
    const [swipeable, setSwipeable] = useState(null)
    const [isNewsLoaded, setIsNewsLoaded] = useState(false)

    const getNewsCategory = () => {
        const query = {
            limit: 15,
            order: '-fields.order',
            content_type: 'category',
        }
        return contentfulClient.getContentfulClient().getEntries(query)
    }

    const { data: newsCategoryData, error } = useQuery({
        queryKey: ['newsCategory'],
        queryFn: getNewsCategory
    })

    useEffect(
        () => {
            if (newsCategoryData) {
                setState({
                    menuGroups: newsCategoryData.items,
                })
                saveContentCategories(newsCategoryData.items)
            }
        },
        /* eslint-disable-next-line */
        [newsCategoryData])

    useEffect(
        () => {
            error &&
                setState({
                    errMessage: `We're sorry, news isn't available at the moment. We'll get it up and running as soon as we can. 
                Thanks for your patience.`,
                })
        },
        /* eslint-disable-next-line */
        [error])

    const handleTabChange = (selectedIndex) => {
        setSelectedCategoryIndex(selectedIndex)
    }

    const updateHeight = () => {
        swipeable && swipeable.updateHeight()
    }

    const getSwipableViewChildren = () => {
        let slides = state.menuGroups.map((item, index) => (
            <div className="min-h-screen py-1" key={item.sys.id}>
                {/* Displays Feature News only on Colleague Discount News page - using the Colleague Discount ID */}
                {item.sys.id === '188f2jKvgsSF7iE9RMbLtd' && (
                    <LatestCategory category={item.sys.id} />
                )}
                <NewsList
                    category={item.sys.id}
                    active={newsState.selectedCategoryIndex === index + 1}
                    updateHeight={updateHeight}
                    setIsNewsLoaded={setIsNewsLoaded}
                />
            </div>
        ))

        return [
            <div className="min-h-screen py-1" key="all">
                <Latest />
                <NewsList
                    category="all"
                    active={newsState.selectedCategoryIndex === 0}
                    updateHeight={updateHeight}
                    setIsNewsLoaded={setIsNewsLoaded}
                />
            </div>,
        ].concat(slides)
    }

    return (
        <section>
            {isNewsLoaded && <LinerProgressBar siteName="" />}

            <NewsCategory
                data={state.menuGroups}
                selectedIndex={newsState.selectedCategoryIndex}
                handleTabChange={handleTabChange}
                animateScroll
            />
            {state.errMessage === '' ? (
                <div className="handheld:mt-2 swipeable-container">
                    {state.menuGroups.length > 0 && (
                        <ReactSwipe
                            handleTabChange={handleTabChange}
                            selectedIndex={newsState.selectedCategoryIndex}
                        >
                            {getSwipableViewChildren()}
                        </ReactSwipe>
                    )}
                </div>
            ) : (
                    <div className="container p-4 mx-auto md:p-8">
                        <div className="flex py-2 text-base md:text-xl">
                            {state.errMessage}
                        </div>
                    </div>
                )}
        </section>
    )
}

export default NewsContainer
