import { useState, useEffect, useReducer } from 'react'
import throttle from 'lodash/throttle'
import { useLocation } from 'react-router-dom'

export const useQueryString = () => new URLSearchParams(useLocation().search)

export const useIsMobile = () => {
  const mobileWidth = 768
  const isMobileWidth = () => window.innerWidth <= mobileWidth
  const getWindowWidth = () => window.innerWidth
  const [isMobile, setIsMobile] = useState(isMobileWidth)
  const [windowWidth, setWindowWidth] = useState(getWindowWidth)

  useEffect(() => {
    const handleResize = throttle(() => {
      setIsMobile(isMobileWidth())
      setWindowWidth(getWindowWidth())
    }, 300)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return [isMobile, windowWidth]
}

export const useSetState = initialState => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    initialState
  )

  return [state, setState]
}

export const useScrollToSection = (ref, id) => {
  const hashId = useLocation()
    .hash.split('#')
    .pop()

  useEffect(
    () => {
      if (hashId === id) {
        ref && ref?.current?.scrollIntoView()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id]
  )
}
