import React from 'react'
import Avatar from './Avatar'
import Welcome from './Welcome'
import Icon from 'components/utils/Icon'
import { Link } from 'react-router-dom'
import SearchIcon from './SearchIcon'
import { useNavigate } from 'react-router-dom'
import { MorrisonsLogo } from 'components/utils'
import { useUserState } from 'store/user-context'
import { useNewsDispatch } from 'store/news-context'
// import BHMOne from '../../images/bhm-bkgd-v1.png'

const Header = ({ isArticle }) => {
  const userState = useUserState()
  const {
    toggleSideBar,
    setSelectedCategoryIndex,
    toggleSearchContainer,
  } = useNewsDispatch()
  const navigate = useNavigate()
  const handleHomeClick = () => {
    setSelectedCategoryIndex(0)
  }
  
  return (
    <div className="relative z-20 bg-green" data-cy="header">
     {/* <div className="relative z-20 bg-gradient-green-black" data-cy="header"> */}
      <div
         className="shadow-md bg-green"
        // className="shadow-md"
        style={{
          // backgroundImage: `url(${ChristmasOne})`,
          // backgroundSize: `contain`,
          // backgroundImage: `url(${BHMOne})`,
          backgroundSize: `40% 100%`,
          backgroundRepeat: `no-repeat`,
          backgroundPosition: `right center`,
        }}
      >
        <div className="container mx-auto md:px-8">
          <header className="relative flex overflow-hidden text-white">
            <div className="flex items-center px-4 md:px-6 md:px-0">
              <Link
                to=""
                onClick={e => {
                  e.preventDefault()
                  navigate(-1)
                }}
                className={isArticle ? 'no-underline md:hidden' : 'hidden'}
              >
                <Icon
                  icon="keyboard_arrow_left"
                  size="32"
                  classStyle="cursor-pointer relative"
                  color="text-white"
                />
              </Link>
              <button
                data-test="Menu"
                onClick={toggleSideBar}
                className={isArticle ? 'hidden md:block' : ''}
              >
                <Icon
                  icon="menu"
                  size="32"
                  classStyle="cursor-pointer relative"
                  color="text-white"
                />
              </button>
            </div>
            <div className="items-center hidden px-4 py-2 md:flex xl:px-4 xl:py-4">
              <Avatar size="60" path={userState.current_user.avatar_url} />
              <Welcome user={userState.current_user} />
            </div>
            <div className={isArticle ? 'hidden' : 'flex items-center mr-1'}>
              {/* <NotificationCenter /> */}
            </div>
            <div
              className={
                isArticle ? 'hidden' : 'flex items-center mx-2 '
              }
            >
              <button onClick={toggleSearchContainer} className="text-white">
                <SearchIcon size="28" classes="relative" color="text-white" />
              </button>
            </div>
            <div className="flex items-center px-4 py-2 ml-auto bg-cover lg:mr-0 md:px-0 xl:py-4">

              {/* <div className="flex items-center px-6 py-2 ml-auto md:px-0 xl:py-4"> */}
              <MorrisonsLogo clickEvent={handleHomeClick} />
            </div>
          </header>
        </div>
      </div>
    </div>
  )
}

export default Header
