import React from 'react'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import {
  Heading2,
  FaqSection,
  Paragraph,
  Strong,
  DocumentSection,
  LinkSection,
  EntryHyperlink,
  NewsSection,
  YouTubeSection,
} from './'

const RichtTextReact = ({richText, overrides})=>{
    return documentToReactComponents(richText, overrides)
}

const Page = props => {
  let rawRichTextField
  if (props.page) {
    rawRichTextField = props.page.body
  }

  return (
    <section className="c-wysiwyg">
      {rawRichTextField && (
        <RichtTextReact
          richText={rawRichTextField}
          overrides={
            {renderMark : {
            [BLOCKS.HEADING_1]: {
              component: Heading2,
            },
            [INLINES.ENTRY_HYPERLINK]: {
              page: EntryHyperlink,
            },
            [BLOCKS.PARAGRAPH]: {
              component: Paragraph,
            },
            [BLOCKS.HEADING_2]: {
              component: Heading2,
            },
        }, renderNode: {
            [BLOCKS.EMBEDDED_ENTRY]: (node) => {
                const embeddedComponentId = node?.data?.target?.sys?.contentType?.sys?.id
                // const { title, description } = node.data.target.fields;
                if(embeddedComponentId === "faqSection"){
                    return <FaqSection fields={node.data.target.fields} />
                }
                if(embeddedComponentId === "articleSection"){
                    return <NewsSection fields={node.data.target.fields} />
                }
                if(embeddedComponentId === "documentSection"){
                    return <DocumentSection fields={node.data.target.fields} />
                }
                if(embeddedComponentId === "linkSection"){
                    return <LinkSection fields={node.data.target.fields} />
                }
                if(embeddedComponentId === "youTubeSection"){
                    return <YouTubeSection fields={node.data.target.fields} />
                }
              },
            strong: {
              component: Strong,
            },
          }}}
        />
      )}
    </section>
  )
}

export default Page
