import React from 'react'
import { ThemeProvider } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress'
import { SITES } from 'constant/common'

import { defaultTheme, covidVirusTheme, wellbeingTheme } from './theme'

const LinerProgressBar = ({ siteName }) => {
  const getTheme = () => {
    let theme = null
    switch (siteName) {
      case SITES.CORONAVIRUS:
        theme = covidVirusTheme
        break
      case SITES.WELLBEING:
        theme = wellbeingTheme
        break
      default:
        theme = defaultTheme
    }
    return theme
  }

  return (
    <div className="fixed z-50 h-2 pin">
      <ThemeProvider theme={getTheme()}>
        <LinearProgress color="primary" />
      </ThemeProvider>
    </div>
  )
}
export default LinerProgressBar
