import React from 'react'
import { Link } from 'react-router-dom'
import { FlyMenu } from '.'
import { usePageState } from 'store/page-context'

const PageMenuItem = ({ item, activePage, setCurrentMenu, isOpen }) => {
  const activeClass = 'active text-primary border-b-2 border-primary'
  const { siteName } = usePageState()
  const getActiveLink = slug => {
    if (activePage === undefined && slug === 'home') return activeClass
    return activePage === slug ? activeClass : 'text-black'
  }

  return (
    <div
      onMouseLeave={() => {
        setCurrentMenu(null)
      }}
    >
      {item === 'home' && (
        <Link
          to={`/sites/${siteName}`}
          className={`${getActiveLink(
            item
          )} block px-3 hover:text-primary py-3 text-sm md:text-base text-left font-medium no-underline bg-white cursor-pointer menu-item flex-no-shrink xl:text-md router-link-exact-active router-link-active`}
          onMouseOver={() => {
            setCurrentMenu(item)
          }}
          onClick={() => {
            setCurrentMenu(null)
          }}
        >
          Home
        </Link>
      )}
      {item &&
        item.href && (
          <>
            <Link
              to={`/sites/${siteName}/${item.href.slug}`}
              key={`/sites/${siteName}/${item.href.slug}`}
              className={`${getActiveLink(
                item.href.slug
              )} block px-3 hover:text-primary py-3 text-sm md:text-base text-left font-medium no-underline bg-white cursor-pointer menu-item flex-no-shrink xl:text-md router-link-exact-active router-link-active`}
              onMouseOver={() => {
                setCurrentMenu(item.href.slug)
              }}
              onClick={() => {
                setCurrentMenu(null)
              }}
            >
              {item.title}
            </Link>
            {item.children && (
              <div
                className={`${
                  isOpen ? 'hidden lg:block' : 'hidden'
                }  absolute bg-white shadow-md`}
              >
                <FlyMenu
                  setCurrentMenu={setCurrentMenu}
                  linkList={item.children}
                />
              </div>
            )}
          </>
        )}
    </div>
  )
}

export default PageMenuItem
