import React, { useState, useEffect } from 'react'
import moment from 'moment'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { Bulletin } from './'

const BulletinGroup = ({ bulletinGroup, isOpen, userLocation }) => {
  const [open, setOpen] = useState(false)

  useEffect(
    () => {
      setOpen(isOpen)
    },
    [isOpen, setOpen]
  )

  return (
    <div
      className="md:border bg-grey-lighter border-grey-light md:mb-4 mb-2 border-t border-b"
      id={bulletinGroup.date}
    >
      <div
        className="flex bg-grey-lighter text-l text-primary cursor-pointer px-4 md:py-4 py-2"
        onClick={() => setOpen(!open)}
      >
        <span className="flex-auto">
          {moment(bulletinGroup.date).format('dddd Do MMMM')}
        </span>
        {bulletinGroup.bulletins.length > 0 && (
          <span
            className={`flex w-6 h-6 overflow-hidden justify-center text-primary rounded-full background bg-grey-light`}
          >
            {bulletinGroup.bulletins.length}
          </span>
        )}
        <span
          className={`flex items-center justify-center text-primary transition duration-400 ${
            open ? 'transform rotate-180' : ''
          }`}
        >
          <ExpandMoreIcon size="small" sx={{ color: '#00683a' }} />
        </span>
      </div>
      {open &&
        bulletinGroup.bulletins.map((bulletin, index) => (
          <Bulletin
            bulletin={bulletin}
            last={bulletinGroup.bulletins.length - 1 === index}
            key={index}
            userLocation={userLocation}
          />
        ))}
    </div>
  )
}

export default BulletinGroup
