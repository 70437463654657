import React from 'react'
import CoronaVirusIdentityBanner from './CoronaVirusIdentityBanner'
import MyWellbeingIdentityBanner from './MyWellbeingIdentityBanner'
import { SITES } from 'constant/common'

const IdentityBanner = ({ siteName, currentPageContent }) => {
  const title = currentPageContent?.fields.title

  return (
    <>
      {siteName === SITES.CORONAVIRUS && (
        <CoronaVirusIdentityBanner title={title} />
      )}
      {siteName === SITES.WELLBEING && (
        <MyWellbeingIdentityBanner title={title} />
      )}

    </>
  )
}

export default IdentityBanner
