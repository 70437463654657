import axios from 'axios'
import { Core } from './index'

const loadData = async (url, method, postPayload) => {
  const token = Core.getToken()
  const options = {
    headers: {
      Accept: 'application/json',
      'X-Token': token || '',
    },
  }

  options.method = method

  if (postPayload) {
    options.data = postPayload
    options.headers = {
      ...options.headers,
      'Content-Type': 'application/json',
    }
  }

  try {
    const resp = await axios({
      method,
      url,
      ...options,
    })
    const http_status = resp.status
    const white_list = {
      200: () => ({ ...resp.data, http_status }),
      201: () => ({ ...resp.data, http_status }),
      204: () => ({ http_status }),
    }
    if (white_list[http_status]) return white_list[http_status]()
    return { treat_as_error: true, http_status }
  } catch (error) {
    if (error.response && error.response.status === 403) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data)
      console.log(error.response.status)
      console.log(error.response.headers)
      window.location = '/timeout'
    } else {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      // browserHistory.push('/error')
      window.location = '/error'
    }
    console.log(`Error calling url: ${url}.  Responded with:`, error.toJSON())
    throw error
  }
}

export default {
  get: url => loadData(url, 'GET'),
  post: (url, postPayload) => loadData(url, 'POST', postPayload),
  http_delete: url => loadData(url, 'DELETE'),
}
