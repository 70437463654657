import React from 'react'

export default props => {
  return (
    <h1 className="text-base font-light">
      Welcome to MyMorri
      <span className="text-2xl xl:text-2xl block font-medium">
        {props.user.first_name} {props.user.last_name}
      </span>
    </h1>
  )
}
