import React, { useEffect } from 'react'
import CategoryList from './CategoryList'
import { LeftArrow, RightArrow } from 'components/utils'
const NewsCategory = props => {
    let categoryListRef = null
    let activeTabNode = null

    useEffect(() => {
        scrollToSelectedCategory()
    }, [props.selectedIndex])

    const handleCategoryClick = selectedIndex => {
        if (props.handleTabChange) props.handleTabChange(selectedIndex)
        window.scrollTo(0, 0)
    }

    const scrollToSelectedCategory = () => {
        const { animateScroll } = props
        const childNodes = categoryListRef?.childNodes
        if (!childNodes.length) return
        for (let i = 0; i < childNodes.length; i++) {
            if (childNodes[i]?.classList?.contains('active-tab')) {
                activeTabNode = childNodes[i]
            }
        }
        if (!activeTabNode) return
        const activeTabRect = activeTabNode.getBoundingClientRect()
        const categoryListRect = categoryListRef.getBoundingClientRect()
        const activeTabLeft = activeTabRect.left - categoryListRect.left
        if (
            activeTabLeft < 0 ||
            activeTabLeft + activeTabRect.width > categoryListRect.width
        ) {
            categoryListRef.scrollBy({
                left: activeTabLeft,
                behavior: `${animateScroll ? 'smooth' : 'auto'}`,
            })
        }
    }

    const handleScrollOnArrowClick = direction => {
        const categoryListOffsetWidth = categoryListRef.offsetWidth
        let scrollBy =
            direction === 'right' ? categoryListOffsetWidth : -categoryListOffsetWidth
        categoryListRef.scrollBy({ left: scrollBy, behavior: 'smooth' })
    }

    return (
        <div className="border-b sticky pin-top-1 z-20 bg-white shadow-md">
            <div className="container flex mx-auto md:px-8">
                <LeftArrow
                    width="9px"
                    height="14px"
                    classes="mr-2 hidden md:block xl:hidden"
                    handleClick={handleScrollOnArrowClick}
                />
                <CategoryList
                    selectedIndex={props.selectedIndex}
                    data={props.data}
                    handleClick={handleCategoryClick}
                    activeTabParentRef={el => (categoryListRef = el)}
                />
                <RightArrow
                    width="9px"
                    height="14px"
                    classes="ml-4 hidden md:block xl:hidden"
                    handleClick={handleScrollOnArrowClick}
                />
            </div>
        </div>
    )
}
export default NewsCategory
