import React from 'react'
import MuIcon from './MuIcon'
import PropTypes from 'prop-types'

const Icon = props => {
  const { icon, svg, color, classStyle, size } = props
  return icon ? (
    <div
      className={`material-icon-wrapper ${classStyle} ${color}`}
      style={{ height: size + 'px' }}
    >
      <MuIcon icon={icon} size={size} />
    </div>
  ) : svg ? (
    <img src={svg} alt="icon" className="icon" color={color} />
  ) : null
}

Icon.propTypes = {
  icon: PropTypes.string,
  svg: PropTypes.string,
  color: PropTypes.string,
  classStyle: PropTypes.string,
  size: PropTypes.string,
}

export default Icon
