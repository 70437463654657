import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Core } from '../../util';

const RetailCommsSearchLine = ({bulletinType, date, id}) => {
  const dates = Core.getWeekDates(date);
  return <Link
      className="flex items-center py-4 no-underline border-b border-gray-400"
      to={`/sites/retail-comms/${dates.weekDate}/${bulletinType.fields.slug}#${id}`}
    >
      <span className="text-sm font-medium text-black pr-1">{bulletinType.fields.title}</span>
      <span className="text-sm font-medium text-green-light">
        {moment(new Date(date)).format('dddd Do MMMM')}
      </span>
    </Link>
}

export default RetailCommsSearchLine
