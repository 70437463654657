import React from 'react'
const UserAccountError = () => {
  return (
    <>
      <div className="text-lg text-center text-orange">
        Oops! There seems to be a problem with your account.
      </div>
      <div className="mt-4 text-lg text-center text-white">
        <p className="pb-4">
          For help with MyMorri, you can call the MyMorri Helpline on{' '}
          <span className="whitespace-no-wrap">0330 041 5587</span>
        </p>
        <p>The Helpline is open 24 hours a day, 365 days a year.</p>
      </div>
    </>
  )
}
export default UserAccountError