import React from 'react'
import Highlighter from 'react-highlight-words'
import { useSitesState } from 'store/sites-context'

const HighlightText = ({ highlightChildren }) => {
  const { highlightString } = useSitesState()
  const createHighlight = (currentElement, key = 0) => {
    if (currentElement !== '')
      return (
        <Highlighter
          key={key}
          highlightClassName="text-secondary bg-tertiary-lightest border px-1"
          searchWords={[highlightString]}
          autoEscape={true}
          textToHighlight={currentElement}
        />
      )
  }

  const highlightText = () => {
    if (
      typeof highlightChildren === 'string' ||
      highlightChildren instanceof String
    ) {
      return createHighlight(highlightChildren)
    }
    const render = Array.isArray(highlightChildren)
      ? highlightChildren.map((element, key) => {
          let currentElement = element
          if (
            typeof currentElement === 'string' ||
            currentElement instanceof String
          ) {
            currentElement = createHighlight(currentElement, key)
          }
          return currentElement
        })
      : null
    return render
  }

  return highlightText()
}

export default HighlightText
