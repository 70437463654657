import React from 'react'
import moment from 'moment';
import RetailCommsIdentityBanner from 'components/sites/IdentityBanner/RetailCommsIdentityBanner'
import { Core } from './'

const tabTitles = [
  "Last Week",
  "Current Week",
  "Next Week",
  "Next Week (+1)",
  "Next Week (+2)",
  "Archive"
];

const bulletinTypes = [
  'Daily Update',
  'Urgents',
  'Weekly Execution Planner',
  'Store Specific Communication',
  'POS Guide'
];

const renderBanner = (title, date) => {
  const weekNumber = date && title ? Core.getWeekNumber(date) : null;
  const weekDates = Core.getWeekDates(date);

  return (
    <section className="bg-grey-transparent">
      <RetailCommsIdentityBanner
        date={getFormattedDate(weekDates.start)}
        title={title || "Loading..."}
        week={weekNumber}
      />
    </section>
  )
}

const getFormattedDate = date => {
  const formattedDate = new Date(date)

  return (
    (formattedDate.getDate() > 9
      ? formattedDate.getDate()
      : '0' + formattedDate.getDate()) +
    '/' +
    (formattedDate.getMonth() > 8
      ? formattedDate.getMonth() + 1
      : '0' + (formattedDate.getMonth() + 1)) +
    '/' +
    formattedDate.getFullYear()
  )
}

const checkDate = dateString => {
  const date = moment.utc(dateString, 'YYYY-MM-DD');
  if (date.isValid()) {
    return date.format('YYYY-MM-DD');
  }
  return null;
}

export {
  bulletinTypes,
  checkDate,
  getFormattedDate,
  tabTitles,
  renderBanner
}
