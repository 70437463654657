import React from 'react'
import { Link } from 'react-router-dom'
import { TheHeader, HeaderTab } from '../components/header'
import { Container, Section } from '../components/utils'
import { SearchContainer } from '../components/search'
import { useNewsState } from 'store/news-context'
import { useSitesState } from 'store/sites-context'

function Sites() {
  const newsState = useNewsState()
  const { navigation } = useSitesState()

  return (
    <>
      <HeaderTab activeTab="mymorri" />
      <TheHeader />
      <section className="bg-grey-transparent">
        <div className="container mx-auto md:px-8">
          <SearchContainer isActive={newsState.isSearchActive} />
        </div>
      </section>
      <Container>
        <Section title="Sites">
          <div className="flex flex-col">
            {navigation && navigation.items
              ? navigation.items.map((item, index) => (
                  <div key={index} className="ml-3">
                    <Link
                      to={`/sites/${item.fields.slug}/home`}
                      className="block mb-3 text-lg font-semibold no-underline text-green-light hover:text-green"
                    >
                      {item.fields.title}
                    </Link>
                  </div>
                ))
              : ''}
          </div>
        </Section>
      </Container>
    </>
  )
}

export default Sites
