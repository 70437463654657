import React from 'react'

const CategoryList = props => {
  return (
    <div
      ref={props.activeTabParentRef}
      className="flex w-full whitespace-no-wrap list-reset flex-nowrap handheld:scrolling-touch handheld:overflow-x-auto md:overflow-x-hidden"
      data-cy="category"
    >
      <button
        className={`flex-no-shrink cursor-pointer whitespace-nowrap bg-white text-black px-3 py-4 lg:py-4 text-sm md:text-base xl:text-md text-left no-underline block ${
          props.selectedIndex === 0
            ? 'text-green-light shadow-tab active-tab'
            : ''
        }`}
        key="all"
        id="all"
        onClick={() => props.handleClick(0)}
      >
        Latest News
      </button>
      {Array.isArray(props.data) &&
        props.data.map((item, index) => {
          return (
            <button
              className={`menu-item flex-no-shrink whitespace-nowrap cursor-pointer bg-white text-black px-3 py-4 lg:py-4 text-sm md:text-base xl:text-md text-left no-underline block router-link-exact-active router-link-active ${
                props.selectedIndex === index + 1
                  ? 'text-green-light shadow-tab active-tab'
                  : ''
              }`}
              key={item.sys.id}
              id={item.sys.id}
              onClick={() => props.handleClick(index + 1)}
              data-test={`Category ${index}`}
            >
              {item.fields.name}
            </button>
          )
        })}
    </div>
  )
}

export default CategoryList
