import React from 'react'
import { SubSection } from '../../utils'
import { SearchYouTubeEntry } from '../'
import { SEARCH_LIMIT } from 'constant/common'

const SiteSearchYouTubeVideos = ({ parsedSearchResults }) => {
  let videos = (parsedSearchResults || []).filter(
    item => item.result.sys.contentType.sys.id === 'youTubeEmbed'
  )
  videos = videos.slice(0, SEARCH_LIMIT)
  return videos && videos.length > 0 ? (
    <SubSection title="Videos">
      <div className="pt-4 md:-mx-4 md:flex md:flex-wrap">
        {videos.map((video, key) => (
          <div key={key} className="card md:w-1/3 lg:w-1/4 md:px-4 md:mb-8">
            <SearchYouTubeEntry
              key={video.result.sys.id}
              fields={video.result.fields}
              sys={video.result.sys}
            />
          </div>
        ))}
      </div>
    </SubSection>
  ) : null
}

export default SiteSearchYouTubeVideos
