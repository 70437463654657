import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { BulletinGroup } from 'components/sites'
import { LinerProgressBar } from 'components/sites/LinerProgressBar'
import { contentfulClient, Core } from '../../util'
import { useUserState } from 'store/user-context'

const groupBulletins = (groups, bulletin) => {
  const date = bulletin.fields.date;
  if (date in groups) {
    groups[date].bulletins.push(bulletin);
  } else {
    groups[date] = {
      date,
      bulletins: [bulletin]
    };
  }
  return groups;
}

const TabContent = ({ bulletinType, week }) => {
  const { hash } = useLocation();
  const [bulletinGroups, setBulletinGroups] = useState([]);
  const [loading, setLoading] = useState(true)
  const userData = useUserState()

  useEffect(
    () => {
      if (week) {
        setLoading(true);

        const dates = Core.getWeekDates(week)

        contentfulClient
          .getConvenienceContentfulClient()
          .getEntries({
            content_type: 'bulletin',
            'fields.bulletinType.sys.id': bulletinType.id,
            'fields.date[gte]': dates.start,
            'fields.date[lte]': dates.end,
            'fields.location[in]': `${userData.current_user.store_id},ALL`,
            order: '-fields.date',
            include: 5
          })
          .then(bulletins => {
            const groups = bulletins.items.reduce(groupBulletins, {});
            setBulletinGroups(Object.values(groups));
            setLoading(false);
          })
          .catch(error => {
            console.error(error);
            setLoading(false);
          });
      }
    },
    [bulletinType, setBulletinGroups, userData.current_user.store_id, week]
  )

  if (loading) {
    return <LinerProgressBar />
  }

  let openId = bulletinGroups[0]?.date;
  if (hash) {
    openId = hash.substring(1);
  }

  return <div className="container mx-auto md:px-8 px-0 pb-4">
    {bulletinGroups.length === 0 
      ? <span className="text-lg px-4">No bulletins of this type available</span> 
      : bulletinGroups.map((bulletinGroup, index) => <BulletinGroup bulletinGroup={bulletinGroup} isOpen={openId === bulletinGroup.date} key={index} userLocation={userData.current_user.store_id} />)
    }
  </div>
}

export default TabContent