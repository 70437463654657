import React from 'react'
import { Link } from 'react-router-dom'
import { useSitesState } from 'store/sites-context'
import { SubSection } from '../../utils'
import { HighlightText } from '../'
import { SEARCH_LIMIT } from 'constant/common'

const SiteSearchPageResults = ({
  parsedSearchResults,
  excludedContentTypesFromPageResult,
}) => {
  const { getPageUrl } = useSitesState()

  let otherResults = (parsedSearchResults || []).filter(
    item =>
      excludedContentTypesFromPageResult.indexOf(
        item.result.sys.contentType.sys.id
      ) === -1
  )
  otherResults = otherResults.slice(0, SEARCH_LIMIT)
  let pageExist = false
  if (otherResults && otherResults.length > 0) {
    otherResults.forEach(result => {
      if (result.pages && result.pages.length > 0) {
        pageExist = true
      }
    })
  }

  return otherResults && otherResults.length > 0 && pageExist ? (
    <SubSection title="Pages">
      <div className="max-w-xl">
        test
        {otherResults.map(result => {
          return result.pages.map(page => {
            const pageUrl = getPageUrl(page)
            const pagePath = pageUrl ? pageUrl.split('/sites/')[1] : ''
            console.log(result.result.fields)
            return (
              <Link
                key={page.fields.slug}
                className="block pb-4 mb-6 no-underline border-b border-gray-400"
                to={pageUrl ? pageUrl : ''}
              >
                <div className="flex flex-col items-start">
                  <div className="flex mb-2 text-sm font-medium uppercase text-green-light">
                    {pagePath && pagePath.replace(/\//gi, ' > ')}
                  </div>
                  <h3 className="font-medium text-black xl:text-lg">
                    <HighlightText
                      highlightChildren={result.result.fields.title}
                    />
                  </h3>
                </div>
              </Link>
            )
          })
        })}
      </div>
    </SubSection>
  ) : null
}

export default SiteSearchPageResults
