import React, { useEffect, useState } from 'react'
import NewsDeck from './NewsDeck'
import { contentfulClient } from '../../util'
import { Container, Section } from 'components/utils'

const NewsList = props => {
  const [newsListState, setNewsListState] = useState({
    newsList: undefined,
    isLoading: false,
  })
  const { category, active } = props

  useEffect(
    () => {
      if (props.active && !newsListState.newsList) {
        fetchNews()
      }
    },
    [category, active]
  )

  function fetchNews() {
    setNewsListState({ ...newsListState, isLoading: true })
    props.setIsNewsLoaded(true)

    const query =
      category === 'all'
        ? {
            limit: 17,
            order: '-fields.publishAt',
            content_type: 'article',
            'fields.publishToHomePage': 'yes',
            'fields.publishAt[lte]': new Date().toISOString(),
          }
        : {
            limit: 20,
            order: '-fields.publishAt',
            content_type: 'article',
            'fields.category.sys.id[in]': category,
            'fields.publishAt[lte]': new Date().toISOString(),
          }
    contentfulClient
      .getContentfulClient()
      .getEntries(query)
      .then(({ items }) => {
        setNewsListState({
          newsList:
            (category === 'all') | (category === '188f2jKvgsSF7iE9RMbLtd')
              ? filterAllNews(items)
              : items,
          isLoading: false,
        })
        props.setIsNewsLoaded(false)
      })
      .catch(error => {
        setNewsListState({
          newsList: [],
          isLoading: false,
        })
        props.setIsNewsLoaded(false)
        if (error && error.response && error.response.status === 403) {
          window.location = '/timeout'
          return
        }
      })
  }

  function filterAllNews(news) {
    let featuredIndex = news.findIndex(item => item.fields.featured === true)
    featuredIndex !== -1 && news.splice(featuredIndex, 1)
    return news
  }

  const newsItems = newsListState.newsList?.length && newsListState.newsList

  return (
    <>
      <Container>
        <Section title="Morrisons news" classes="hidden lg:block">
          {newsItems?.length && <NewsDeck newsItems={newsItems} />}
        </Section>
      </Container>
    </>
  )
}

export default NewsList
