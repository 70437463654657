import React from 'react'
import { Link } from 'react-router-dom'
import { createPageUrl } from '../../util/sites'

const EntryHyperlink = ({ fields, children, sys }) => {
  function isPage() {
    return fields && fields.slug && sys
  }

  const link = isPage() ? createPageUrl(fields.site, { fields, sys }) : ''

  return <Link to={link}>{children}</Link>
}

export default EntryHyperlink
