import { handleError } from './error'

export const getParseData = entries => {
  const createFlatMap = () => {
    let flatMap = (entries || []).map(item => {
      return {
        title: item?.fields?.title,
        href: { slug: item?.fields?.slug, url: item?.fields?.slug },
        id: item?.sys?.id,
        parentId: item?.fields?.parentID?.sys?.id,
        order: item?.fields?.order,
        date: item?.fields?.date,
      }
    })

    // sort by order
    flatMap.sort((item1, item2) => {
      const undefinedMax = 10000000
      const order1 = item1.order || undefinedMax
      const order2 = item2.order || undefinedMax
      return order1 - order2
    })
    return flatMap
  }

  const generateSitesPageUrl = tempPages => {
    const sitePageUrls = []
    const siteMap = []
    const siteUrl = []

    const generatePageUrl = (pages, path = '/sites') => {
      let currentUrl = ''
      try {
        if (pages) {
          currentUrl = `${path}/${pages.href.slug}`

          const pageUrlKey = pages.id
          if (pages.children) {
            pages.href.url = currentUrl
            sitePageUrls[pageUrlKey] = currentUrl
            siteMap[currentUrl] = {
              id: pageUrlKey,
              title: pages.title,
            }
            siteUrl[pageUrlKey] = currentUrl
            pages.children.forEach(page => {
              if (page) generatePageUrl(page, currentUrl)
            })
          } else {
            pages.href.url = currentUrl
            sitePageUrls[pageUrlKey] = currentUrl
            siteMap[currentUrl] = {
              id: pageUrlKey,
              title: pages.title,
            }
            siteUrl[pageUrlKey] = currentUrl
          }
        }
      } catch (error) {
        handleError(error)
      }
    }

    tempPages &&
      tempPages.forEach(sites => {
        generatePageUrl(sites)
      })

    return { parseData: tempPages, pageSiteMap: siteMap, siteUrl }
  }

  function generateSiteTree(siteDataArray) {
    let siteTree = [],
      siteTreeMap = {},
      siteTreeElement,
      mappedElement

    // First map the nodes of the array to an object -> create a hash table.
    for (let i = 0, len = siteDataArray.length; i < len; i++) {
      siteTreeElement = siteDataArray[i]
      siteTreeMap[siteTreeElement.id] = siteTreeElement
      siteTreeMap[siteTreeElement.id]['children'] = []
    }

    for (let id in siteTreeMap) {
      if (siteTreeMap.hasOwnProperty(id)) {
        mappedElement = siteTreeMap[id]
        // If the element is not at the root level, add it to its parent array of children.
        if (mappedElement.parentId) {
          siteTreeMap[mappedElement['parentId']] &&
            siteTreeMap[mappedElement['parentId']]['children'].push(
              mappedElement
            )
        }
        // If the element is at the root level, add it to first level elements array.
        else {
          siteTree.push(mappedElement)
        }
      }
    }
    return siteTree
  }

  const flatMap = createFlatMap()
  const treeMap = Array.isArray(flatMap) && generateSiteTree(flatMap)
  return generateSitesPageUrl(treeMap)
}
