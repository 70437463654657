import React from 'react'
import { Link } from 'react-router-dom'
import { useSitesState } from 'store/sites-context'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

const MuIcon = ({ linkIcon, linkSize }) => {
  return (
    <>
      {linkIcon === 'open_in_new' && (
        <OpenInNewIcon
          sx={{ color: '#00683a', fontSize: linkSize }}
          className="border"
        />
      )}
      {linkIcon === 'keyboard_arrow_right' && (
        <KeyboardArrowRightIcon
          sx={{ color: '#00683a', fontSize: linkSize }}
          className="border"
        />
      )}
    </>
  )
}

function LinkCard({ refLink, linkIcon, linkSize }) {
  const { fields } = refLink
  const title = fields?.title
  const image = fields?.image
  const linkInfo = fields?.linkInformation

  return fields ? (
    <>
      <div className="card-link__bg">
        <img src={image?.fields?.file?.url} alt={image?.fields?.title} />
      </div>
      <div className="card-link__wrapper">
        <h3 className="card-link__header">
          <span className="text-xl md:text-2xl">{title}</span>
        </h3>
        <div className="flex items-center justify-between pt-6 mt-0 border-t md:mt-2 card-link__more">
          <span>{linkInfo ? linkInfo : 'More information'}</span>
          <MuIcon icon={linkIcon} size={linkSize} />
        </div>
      </div>
    </>
  ) : null
}

const LinkEntry = ({ refLink }) => {
  const { getPageUrl } = useSitesState()
  const { fields, sys } = refLink
  let link = fields && fields.link

  function getLink() {
    return sys.contentType.sys.id === 'page' ? getPageUrl(refLink) : link
  }

  return fields ? (
    <div className="w-full px-2 pb-4 sm:w-1/2 lg:w-1/4">
      <article className="card-link">
        {link ? (
          <a target="_blank" rel="noopener noreferrer" href={getLink()}>
            <LinkCard {...{ refLink }} linkIcon="open_in_new" linkSize="18px" />
          </a>
        ) : (
          <Link to={getLink()}>
            <LinkCard
              {...{ refLink }}
              linkIcon="keyboard_arrow_right"
              linkSize="24px"
            />
          </Link>
        )}
      </article>
    </div>
  ) : null
}

export default LinkEntry
