import React, { useEffect, useState } from 'react'
import NewsDeck from '../news/NewsDeck'
import { contentfulClient } from '../../util'

const NewsSection = props => {
  const { fields } = props
  const [news, setNews] = useState()

  const getSiteIds = () => {
    const { site } = fields
    const siteIds =
      site &&
      site.map(currentSite => {
        return currentSite.sys.id
      })
    return siteIds.length > 0 ? siteIds.join(',') : null
  }

  const getBusinessUnitIds = () => {
    const { businessUnit } = fields
    const businessUnitIds =
      businessUnit &&
      businessUnit.map(currentBusinessUnit => {
        return currentBusinessUnit.sys.id
      })
    return businessUnitIds?.length > 0 ? businessUnitIds.join(',') : null
  }

  const query = {
    content_type: 'article',
    'fields.site.sys.id[all]': getSiteIds(),
    'fields.businessUnit.sys.id[all]': getBusinessUnitIds(),
    'fields.publishAt[lte]': (new Date()).toISOString(),
    order: '-fields.publishAt',
  }

  useEffect(
    () => {
      fields?.articles?.length === 0 &&
        contentfulClient
          .getContentfulClient()
          .getEntries(query)
          .then(entries => {
            entries ? setNews(entries) : setNews(false)
          })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fields]
  )

  return (
    <section className="mt-10 mb-10">
      <h2 className="mb-6 text-lg font-semibold md:text-xl">{fields.title}</h2>
      {news?.total > 0 &&
        news.items && <NewsDeck siteNews={true} newsItems={news.items} />}

      {fields?.articles && (
        <NewsDeck siteNews={true} newsItems={fields.articles} />
      )}
    </section>
  )
}

export default NewsSection
