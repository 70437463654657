import React from 'react'
import { DateWidget } from 'components/utils'
import { useNavigate } from 'react-router-dom'
import { Container } from 'components/utils'
import { Core } from '../../util'

const SuggestedNews = props => {
    const navigate = useNavigate()

  const getImageUrl = item =>
    item.fields.imageTeaser.fields.file.url + '?fit=thumb&w=289&h=179'

  const renderSuggestedNews = suggestedItems => {
    return suggestedItems.map(item => (
      <div
        className="w-2/3 pr-4 card flex-no-shrink md:px-4 md:w-1/3"
        key={item.sys.id}
      >
        <div
          className="text-black no-underline"
          onClick={() => navigate('/news/' + item.sys.id)}
        >
          <article className={`cursor-pointer`}>
            <div className="flex-none w-full h-auto">
              <div className="relative overflow-hidden rounded-sm aspect-ratio-16/9 lg:rounded">
                <img
                  src={getImageUrl(item)}
                  alt="News Card"
                  className="absolute block object-cover h-full min-w-full pin"
                />
              </div>
            </div>
            <div className="flex-1 py-6">
              <h3 className="mb-1 mb-4 font-medium">{item.fields.title}</h3>
              <DateWidget
                className="block mb-2 text-sm font-medium text-green-light"
                format="MMMM Do YYYY"
                value={item.fields.publishAt}
              />
              <p className="hidden leading-normal text-md md:block">
                {item.fields.summary}
              </p>
            </div>
          </article>
        </div>
      </div>
    ))
  }

  const { newsItems, currentNewsId } = props
  const filteredNewsArray = (newsItems || []).filter(
    item => item.sys.id !== currentNewsId
  )
  const suggestedItems = Core.findNext(filteredNewsArray, 0, 3)
  if (!suggestedItems.length) return ''
  return (
    <section className="py-6 md:pb-10 md:pt-0">
      <Container>
        <div className="mb-2 text-lg font-semibold md:text-xl">
          You might also be interested in
        </div>
        <div className="flex pt-6 overflow-x-auto scrolling-touch md:-mx-4 lg:overflow-x-hidden">
          {renderSuggestedNews(suggestedItems)}
        </div>
      </Container>
    </section>
  )
}

export default SuggestedNews
