import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import logo from 'images/large_tree_logo.png'
import Button from '@mui/material/Button'
import ReactGA from 'react-ga4'

const Timeout = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  useEffect(
    () => {
      ReactGA.send({ hitType: "pageview", page: pathname, title: "Timeout" })
    },
    [pathname]
  )
  return (
    <div
      id="main_content_panel"
      style={{ textAlign: 'center' }}
      className="layout-column scrollable layout-align-center"
    >
      <img alt="morrisons logo" height="200" src={logo} />
      <h1>My Morri</h1>
      <div style={{ padding: '10px' }} />
      <div style={{ color: 'red' }}>You are not signed in</div>
      <br />
      <Button
        variant="contained"
        onClick={() => navigate('/', { replace: true })}
        color="primary"
      >
        Continue
      </Button>
    </div>
  )
}

export default Timeout
