import React from 'react'
import { Container } from '../../utils'
import BannerBackground from 'assets/icons/svg/wellbeing-bkgd.svg'

const MyWellbeingIdentityBanner = ({ title }) => {
  return (
    <header className="relative py-8 mb-6 md:py-10 bg-gradient-wellbeing">
      <Container>
        <div className="flex">
          <div>
            <span className="block text-lg font-semibold text-white md:text-2xl md:font-medium">
              My Wellbeing
            </span>
            <h1 className="text-3xl font-semibold text-white md:text-4xl lg:text-4xl">
              {title}
            </h1>
          </div>
        </div>
      </Container>
      <div className="absolute z-0 flex justify-end h-full overflow-hidden opacity-10 md:w-full pin-t pin-r pin-b">
        <img
          src={BannerBackground}
          alt="MyWellbeing Title Icon"
          className="w-1/2 sm:w-auto"
        />
      </div>
    </header>
  )
}

export default MyWellbeingIdentityBanner
