import React from 'react'
const NotMorrisonsEmailError = ({ email }) => {
  return (
      <>
          <div className="text-lg text-center text-orange">
              It looks like you've tried to sign into MyMorri using the wrong email address.
              <div className="mt-4 fw-bold ">
                  {email}
              </div>
          </div>
          <div className="mt-4 text-lg text-center text-white">
              <p className="pb-4">
                  Please open a new window and sign in again using your Morrisons email address (@morrisonsplc.co.uk)
              </p>
              <p>If you're still having problems, please speak to your line manager or call the MyMorri Helpline on 0330 041 5587 (open 24 hours a day, 365 days a year)</p>
          </div>
      </>
  )
}
export default NotMorrisonsEmailError