import React from 'react'
import ReactPlayer from 'react-player'
import Modal from '@mui/material/Modal'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import { DateWidget } from 'components/utils'
import Play from 'images/play.svg'
import { HighlightText } from './'

const getVideoID = url => {
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  var match = url.match(regExp)
  return match && match[7].length === 11 ? match[7] : false
}

const SearchYouTubeEntry = ({ fields, sys }) => {
  const { title, url, summary } = fields
  const { createdAt } = sys

  const youtubeThumbnail =
    'https://img.youtube.com/vi/' + getVideoID(url) + '/hqdefault.jpg'

  const [open, setOpen] = React.useState(false)

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const videoModal = (
    <>
      <div
        className="fixed z-50 flex items-center justify-center w-8 h-8 m-4 text-white cursor-pointer pin-t pin-r"
        onClick={handleClose}
      >
        <CancelPresentationIcon sx={{ color: '#00683a', fontSize: '32px' }} />
      </div>
      <div className="w-full">
        <div className="mx-auto" style={{ maxWidth: '1024px' }}>
          <div className="relative player-wrapper focus:outline-none">
            <ReactPlayer
              url={url}
              playing
              width="100%"
              height="100%"
              className="absolute pin-l pin-t"
            />
          </div>
        </div>
      </div>
    </>
  )

  return fields ? (
    <>
      <article
        onClick={handleOpen}
        className="h-full cursor-pointer group handheld:flex handheld:py-2 handheld:border-solid handheld:border-b handheld:border-ligher-grey"
      >
        <div className="flex-none w-32 h-auto md:w-full">
          <div className="relative overflow-hidden rounded-sm aspect-ratio-16/9 lg:rounded">
            <div className="absolute z-30 items-center justify-center hidden bg-black-transparent pin group-hover:flex">
              <img alt="play" src={Play} className="w-16 h-16" />
            </div>
            <img
              src={youtubeThumbnail}
              alt="newscard"
              className="absolute block object-cover h-full min-w-full pin"
            />
          </div>
        </div>
        <div className="flex-1 handheld:ml-4 md:pt-6">
          <h3 className="mb-1 font-medium text-1xl xl:text-xl md:mb-4">
            <HighlightText highlightChildren={title} />
          </h3>
          <DateWidget
            className="block text-sm font-medium text-green-light md:mb-2"
            format="MMMM Do YYYY"
            value={createdAt}
          />
          <p className="hidden text-base leading-normal text-md md:block">
            <HighlightText highlightChildren={summary} />
          </p>
        </div>
      </article>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="view myMorri youtube video"
        aria-describedby="play this youtube video from myMorri"
        className="fixed flex flex-col items-stretch justify-between w-screen h-screen p-4 border focus:outline-none md:p-6 lg:p-12 xl:p20 bg-black-transparent-60"
      >
        {videoModal}
      </Modal>
    </>
  ) : null
}

export default SearchYouTubeEntry
