import React from 'react'
import {
  Tab as ReactTab,
  Tabs as ReactTabs,
  TabList,
  TabPanel,
} from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import TabContent from './TabContent'
import { LinerProgressBar } from '../../components/sites/LinerProgressBar'


const RetailContent = ({ bulletinType, bulletinTypes, onBulletinTypeClick, week }) => {
  if (bulletinTypes === null) {
    return <LinerProgressBar />
  }

  return <ReactTabs
      selectedIndex={bulletinTypes.indexOf(bulletinType)}
      onSelect={index => onBulletinTypeClick(bulletinTypes[index])}
    >
      <div className="sticky z-20 bg-white pin-t mb-4 border-b border-grey-light">
        <div className="container flex mx-auto md:px-8">
          <TabList className="flex whitespace-no-wrap list-reset flex-nowrap handheld:scrolling-touch handheld:overflow-x-auto md:overflow-x-hidden">
            {bulletinTypes.map(bulletinType => (
              <ReactTab
                key={bulletinType.id}
                className="text-black block px-3 hover:text-primary py-3 text-sm md:text-base text-left font-medium no-underline bg-white cursor-pointer menu-item flex-no-shrink xl:text-md router-link-exact-active router-link-active"
              >
                {bulletinType.title}
              </ReactTab>
            ))}
          </TabList>
        </div>
      </div>
      {bulletinTypes.map((bulletinType, index) => {
        return <TabPanel key={index}>
          <TabContent
            bulletinType={bulletinType}
            week={week}
          />
        </TabPanel>
      })}
    </ReactTabs>
}

export default RetailContent
