import React, { useEffect } from 'react'
import { Icon, Overlay } from 'components/utils'
import { Avatar } from '../header'
import MenuItem from './MenuItem'
import MenuList from './MenuList'
import ReactGA from 'react-ga4'
import config from 'config'
import { useUserState } from 'store/user-context'
import { useNewsDispatch, useNewsState } from 'store/news-context'

const SideBar = () => {
  const userState = useUserState()
  const { toggleSideBar } = useNewsDispatch()
  const newsState = useNewsState()

  useEffect(
    () => {
      newsState.isSideBarActive
        ? document.body.classList.add('handheld:overflow-hidden')
        : document.body.classList.remove('handheld:overflow-hidden')
    },
    [newsState.isSideBarActive]
  )

  const goto = (url, text) => {
    ReactGA.event({
      category: 'Navigation',
      action: 'Nav Item Click',
      label: text,
    })
    window.open(url, '_blank')
  }

  const logout = () => {
    // window.ga('send', 'event', 'Account', 'Logout')
    ReactGA.event({
      category: 'Account',
      action: 'Logout',
      label: 'Logout',
    })
    window.location = 'https://accounts.google.com/logout'
  }

  const userInfo = () => {
    const currentUser = userState.current_user

    return (
      <div className="leading-normal">
        <span>{currentUser.employee_id}</span>

        {currentUser.store_name ? (
          <span className="block">
            {currentUser.store_name} ({currentUser.store_id})
          </span>
        ) : null}

        {currentUser.role ? (
          <span className="block">{currentUser.role}</span>
        ) : null}

        {currentUser.department ? (
          <span className="block">{currentUser.department}</span>
        ) : null}
      </div>
    )
  }

  return (
    <div
      className={newsState.isSideBarActive ? 'isActive' : 'notActive'}
      data-cy="navbar"
    >
      <nav className="fixed w-4/5 h-screen max-w-sm px-4 py-4 bg-white z-70 o-nav pin-t pin-b handheld:overflow-y-auto">
        <header className="pb-4">
          <div className="flex items-center mb-2 a">
            <span className="text-lg text-green">Welcome to MyMorri</span>
            <button
              className="ml-auto mr-1"
              style={{ outline: 'none' }}
              onClick={toggleSideBar}
            >
              <Icon icon="close" size="30" classStyle="text-green-light" />
            </button>
          </div>
          <Avatar size="40" path={userState.current_user.avatar_url} />
          <span className="block leading-normal text-green-light">
            {userState.current_user.first_name}{' '}
            {userState.current_user.last_name}
          </span>
          {userInfo()}
        </header>
        <MenuList>
          <MenuItem text="Logout" icon="power_settings_new" onClick={logout} />
          <MenuItem
            text="Help and Support"
            icon="help_outline"
            onClick={() =>
              goto(
                'https://sites.google.com/a/morrisonsplc.co.uk/mymorri-help-and-support/home',
                'Help and Support'
              )
            }
          />{' '}
          <MenuItem
            text="2SV Settings"
            icon="fingerprint"
            onClick={() => goto(config.twoStepLink, '2SV Settings')}
          />
        </MenuList>
        <ul className="py-4 font-light list-reset">
          <li className="mb-4">
            For help with MyMorri, you can call the MyMorri Helpline on 0330 041
            5587
          </li>
          <li className="mb-2">
            The Helpline is open 24 hours a day, 365 days a year.
          </li>
        </ul>
      </nav>
      <Overlay
        classes="o-overlay pin bg-black-transparent z-60 w-screen h-screen fixed"
        handleClick={toggleSideBar}
      />
    </div>
  )
}

export default SideBar
