import React from 'react'

const ArticleBanner = props => {
  return (
    <div className="w-full aspect-ratio-16/9 relative overflow-hidden rounded lg:rounded-lg">
      <img
        src={props.src}
        alt={props.alt}
        className="block w-full absolute pin-x pin-y w-full"
      />
    </div>
  )
}

export default ArticleBanner
