import React from 'react'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import InfoIcon from '@mui/icons-material/Info'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import MenuIcon from '@mui/icons-material/Menu'
import NotificationsIcon from '@mui/icons-material/Notifications'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import FingerprintIcon from '@mui/icons-material/Fingerprint'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'

const MuIcon = ({ icon, size, color }) => {
  return (
    <>
      {icon === 'open_in_new' && (
        <OpenInNewIcon sx={{ color: color, fontSize: size + 'px' }} />
      )}
      {icon === 'info' && (
        <InfoIcon sx={{ color: color, fontSize: size + 'px' }} />
      )}
      {icon === 'keyboard_arrow_left' && (
        <KeyboardArrowLeftIcon sx={{ color: color, fontSize: size + 'px' }} />
      )}
      {icon === 'menu' && (
        <MenuIcon sx={{ color: color, fontSize: size + 'px' }} />
      )}
      {icon === 'notifications' && (
        <NotificationsIcon sx={{ color: color, fontSize: size + 'px' }} />
      )}
      {icon === 'search' && (
        <SearchIcon sx={{ color: color, fontSize: size + 'px' }} />
      )}
      {icon === 'close' && (
        <CloseIcon sx={{ color: color, fontSize: size + 'px' }} />
      )}
      {icon === 'help_outline' && (
        <HelpOutlineIcon sx={{ color: color, fontSize: size + 'px' }} />
      )}
      {icon === 'fingerprint' && (
        <FingerprintIcon sx={{ color: color, fontSize: size + 'px' }} />
      )}
      {icon === 'power_settings_new' && (
        <PowerSettingsNewIcon sx={{ color: color, fontSize: size + 'px' }} />
      )}
    </>
  )
}

export default MuIcon
