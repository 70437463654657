import { contentfulClient } from './'
import { handleError } from './error'

export function getParentPage(entry) {
  const items = []
  return new Promise(async (resolve, reject) => {
    try {
      if (entry?.sys?.contentType?.sys?.id === 'page') {
        items.push(entry)
        resolve(items)
      } else {
        const parents = await contentfulClient
          .getContentfulClient()
          .getEntries({
            links_to_entry: entry?.sys?.id,
          })
        let resultPromises = []
        for (let i = 0; i < parents?.items.length; i++) {
          resultPromises.push(getParentPage(parents.items[i]))
        }
        const results = await Promise.all(resultPromises)
        items.push(results)
        resolve(items.flat(Infinity))
      }
    } catch (error) {
      reject(error)
    }
  })
}

export const createPageUrl = (item, page, parentLink = '/sites') => {
  let myUrl = null
  try {
    if (item && item.fields) {
      if (
        item.fields.slug === page.fields.slug &&
        item.sys.id === page.sys.id
      ) {
        return `${parentLink}/${item.fields.slug}`
      }
      if (item.fields.subPages) {
        for (let i = 0; i < item.fields?.subPages.length; i++) {
          const subItem = item.fields?.subPages[i]
          myUrl = createPageUrl(
            subItem,
            page,
            `${parentLink}/${item.fields.slug}`
          )
          if (myUrl) {
            return myUrl
          }
        }
      }
    }
  } catch (error) {
    handleError(error)
  }
}

export const getPageTitle = (item, pageId) => {
  let title = null
  for (let i = 0; i < item.length; i++) {
    const subItem = item[i]
    title = getSubPageTitle(subItem, pageId)
    if (title) return title
  }
  return title
}

export const getSubPageTitle = (item, pageId) => {
  let title = null

  if (item && item.href) {
    if (item.id === pageId) {
      return item.title
    }
    if (item.children) {
      for (let i = 0; i < item?.children.length; i++) {
        const subItem = item?.children[i]
        title = getSubPageTitle(subItem, pageId)
        if (title) {
          return title
        }
      }
    }
  }
}

export const getPageUrl = (mainNavigation, page) => {
  const links = (mainNavigation || []).map(navigation => {
    const pageUrl = createPageUrl(navigation, page)
    return pageUrl ? pageUrl : null
  })
  return links.find(link => link)
}

export const getMainNavigation = items => {
  return items && items?.sys?.contentType?.sys?.id === 'mainNavigation'
    ? items?.fields?.subPages
    : items
}

export const getSubMenu = (items, rootNav) => {
  const navParent = items
    ? items.find(subNavigation => subNavigation?.href?.slug === rootNav)
    : null
  return navParent ? navParent?.children : null
}
