import React from 'react'
import { Remarkable } from '../../util'

Remarkable.configure({
  html: true,
  linkTarget: '_blank',
})

const ArticleContent = props => {
  return (
    <div className="relative z-10 pb-4 mt-8 bg-white md:mt-10 md:pb-8">
      <div
        className="c-wysiwyg"
        dangerouslySetInnerHTML={{
          __html: Remarkable.getHTML(props?.content),
        }}
      />
    </div>
  )
}

export default ArticleContent
