import React, { useEffect, useReducer } from "react";
import { useSwipeable } from "react-swipeable"
import styled from "styled-components";
export const NEXT = "NEXT";
export const PREV = "PREV";
export const SWIPING = "SWIPING";
export const SWIPENDLEFT = "SWIPENDLEFT";
export const SWIPENDRIGHT = "SWIPENDRIGHT";

const calculateTransPercentage = (swipingData) => {
    const width = window.innerWidth
    let swipePercent = (Math.round(swipingData.absX) / width * 100)
    return swipePercent
}

const calculateTransX = (swipingData, pos = 0) => {
    let swipePercent = calculateTransPercentage(swipingData, pos)
    if (swipingData.dir === "Left") {
        swipePercent = "-" + ((pos * 100) + swipePercent)
    }
    if (swipingData.dir === "Right") {
        if (pos > 0) {
            swipePercent = "-" + (((pos) * 100) - swipePercent)
        }
    }
    return swipePercent + '%'
}

export const Item = styled.div`
  text-align: center;
  padding: 100px;
  background-image: ${props => `url(${props.img})`};
  background-size: cover;
`;

export const CarouselContainer = styled.div`
  display: flex;
  @media (max-width: 767px) {
    transition: ${props => (props.sliding ? "transform 1s ease" : "none")};
  }
  transition:  "none";
  transform: ${props => {
        if (props.swiping === SWIPING) {
            const transX = calculateTransX(props.swipingdata, props.pos)
            return "translateX(" + transX + ")";
        }
        if (props.pos === 0) {
            return "translateX(0%)";
        }
        const trans = (props.pos * 100)
        return "translateX(calc(-" + trans + "% - " + (props.pos * 20) + "px))";
    }};
`;

export const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
  box-shadow: 5px 5px 20px 7px rgba(168, 168, 168, 1);
`;

export const CarouselSlot = styled.div`
  flex: 1 0 100%;
  flex-basis: 100%;
  margin-right: 20px;
  order: ${props => props.order};
`;

const getOrder = ({ index, pos, numItems }) => {
    return pos < 0 ? numItems - Math.abs(index - pos) : index - pos;
};

const initialState = { pos: 0, sliding: false, dir: NEXT, swiping: null, swipingData: null, transition: 0 }

export const ReactSwipe = ({ children, handleTabChange, selectedIndex }) => {
    const [state, dispatch] = useReducer(reducer, { ...initialState, pos: selectedIndex });
    const numItems = React.Children.count(children);

    useEffect(() => {
        dispatch({ type: "selectedIndex", selectedIndex });
    }, [selectedIndex])

    useEffect(() => {
        handleTabChange(state.pos)
    }, [state.pos])

    const handleOnSwiping = (swipingData) => {
        if (!(state.swiping === SWIPENDLEFT || state.swiping === SWIPENDRIGHT)) {
            dispatch({ type: SWIPING, swipingData });
        }
    }

    const handleOnSwipedLeft = (swipingData) => {
        if (state.pos === numItems - 1) {
            dispatch({ type: SWIPENDLEFT, swipingData });

            setTimeout(() => {
                dispatch({ type: "stopSliding" });
            }, 50);
        } else {
            const calculatedTransX = calculateTransPercentage(swipingData)
            if (calculatedTransX > 40)
                dispatch({ type: NEXT, numItems });
            else
                dispatch({ type: SWIPENDLEFT, swipingData });

            setTimeout(() => {
                dispatch({ type: "stopSliding" });
            }, 50);
        }
    }

    const handleOnSwipedRight = (swipingData) => {
        if (state.pos === 0) {
            dispatch({ type: SWIPENDRIGHT, swipingData });

            setTimeout(() => {
                dispatch({ type: "stopSliding" });
            }, 50);
        } else {
            const calculatedTransX = calculateTransPercentage(swipingData)
            if (calculatedTransX > 40)
                dispatch({ type: PREV, numItems });
            else
                dispatch({ type: SWIPENDRIGHT, swipingData });

            setTimeout(() => {
                dispatch({ type: "stopSliding" });
            }, 50);
        }

    }

    const handlers = useSwipeable({
        // onSwiping: handleOnSwiping,
        // onSwipedLeft: handleOnSwipedLeft,
        // onSwipedRight: handleOnSwipedRight,
        preventDefaultTouchmoveEvent: true,
        trackMouse: false,
        trackTouch: true
    })

    return (
        <div {...handlers}>
            <div style={{ overflow: "hidden", width: '100%' }} className="react-swipeable-view-container">
                <CarouselContainer
                    dir={state.dir}
                    sliding={state.sliding.toString()}
                    pos={state.pos}
                    swiping={state.swiping}
                    swipingdata={state.swipingData}
                >
                    {React.Children.map(children, (child, index) => (
                        <CarouselSlot
                            key={index}
                            order={getOrder({ index: index, pos: state.pos, numItems })}
                        >
                            {child}
                        </CarouselSlot>
                    ))}
                </CarouselContainer>
            </div>
        </div>
    )
}

function reducer(state, { type, numItems, selectedIndex, swipingData, transX }) {
    switch (type) {
        case "reset":
            return initialState;
        case PREV:
            return {
                ...state,
                dir: PREV,
                sliding: true,
                pos: state.pos === 0 ? numItems - 1 : state.pos - 1
            };
        case NEXT:
            return {
                ...state,
                dir: NEXT,
                sliding: true,
                pos: state.pos === numItems - 1 ? 0 : state.pos + 1
            };
        case "selectedIndex":
            return {
                ...state,
                dir: state.pos > selectedIndex ? NEXT : PREV,
                sliding: true,
                pos: selectedIndex
            };
        case SWIPING:
            return {
                ...state,
                swiping: SWIPING,
                swipingData: swipingData
            };
        case SWIPENDLEFT:
            return {
                ...state,
                swiping: SWIPENDLEFT,
                swipingData: swipingData,
                transition: calculateTransPercentage(swipingData)
            };
        case SWIPENDRIGHT:
            return {
                ...state,
                swiping: SWIPENDRIGHT,
                swipingData: swipingData,
                transition: calculateTransPercentage(swipingData)
            };
        case "stopSliding":
            return { ...state, sliding: false, swiping: null, swipingData: null };
        case "transPosition":
            return { ...state, transition: transX };
        default:
            return state;
    }
}



