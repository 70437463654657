import React from 'react'
import MyGroups from './MyGroups'
import MyServices from './MyServices'
import SearchContainer from '../search/SearchContainer'
import MyInfoSelected from 'assets/icons/svg/my-info-selected.svg'
import MyInfoUnSelected from 'assets/icons/svg/my-info-unselected.svg'
import MyPaySelected from 'assets/icons/svg/my-pay-selected.svg'
import MyPayUnSelected from 'assets/icons/svg/my-pay-unselected.svg'
import MyWorkSelected from 'assets/icons/svg/my-work-selected.svg'
import MyWorkUnSelected from 'assets/icons/svg/my-work-unselected.svg'
import MyVoiceUnSelected from 'assets/icons/svg/my-voice-unselected.svg'
import MyVoiceSelected from 'assets/icons/svg/my-voice-selected.svg'
import { useSetState } from '../../util'
import { useNewsState } from 'store/news-context'

const Application = () => {
  const defaultState = {
    activeIndex: null,
    tabs: [
      {
        title: 'My Work',
        id: 1,
        selectedSvg: MyWorkSelected,
        unSelectedSvg: MyWorkUnSelected,
      },
      {
        title: 'My Info',
        id: 2,
        selectedSvg: MyInfoSelected,
        unSelectedSvg: MyInfoUnSelected,
      },
      {
        title: 'My Voice',
        id: 4,
        selectedSvg: MyVoiceSelected,
        unSelectedSvg: MyVoiceUnSelected,
      },
      {
        title: 'My Benefits',
        id: 3,
        selectedSvg: MyPaySelected,
        unSelectedSvg: MyPayUnSelected,
      },
    ],
  }

  const [state, setState] = useSetState(defaultState)

  const newsState = useNewsState()

  const makeTabActive = val => {
    if (state.activeIndex === val) {
      setState({
        activeIndex: null,
      })
    } else {
      setState({
        activeIndex: val,
      })
    }
  }

  return (
    <section className="py-1 bg-grey-transparent">
      <div className="container mx-auto md:px-8">
        <SearchContainer isActive={newsState.isSearchActive} />
        <div className="md:py-4">
          <div className="flex lg:flex-wrap md:-mx-2 lg:-mx-2 xl:-mx-4 scroll-top-buttons">
            {state.tabs.map((tab, index) => (
              <MyGroups
                index={tab.id - 1}
                active={tab.id - 1 === state.activeIndex}
                key={tab.id}
                title={tab.title}
                selectedSvg={tab.selectedSvg}
                unSelectedSvg={tab.unSelectedSvg}
                makeActive={val => makeTabActive(val)}
              />
            ))}
          </div>
        </div>
        {state.activeIndex !== null && (
          <MyServices activeIndex={state.activeIndex} />
        )}
      </div>
    </section>
  )
}
export default Application
