import React, { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { contentfulClient } from '../../util'
import { Container } from '../utils'
import { Page, MobileNavigation } from './'
import { getSubMenu } from '../../util/sites'
import { usePageState } from 'store/page-context'
import { LinerProgressBar } from './LinerProgressBar'
import { IdentityBanner } from './IdentityBanner'
import { ErrorMessage } from 'components/error'
import BannerIcon from 'assets/icons/svg/coronavirus-cta.svg'

const getPageContent = (key, pageId) => {
  return contentfulClient.getContentfulClient().getEntries({
    'sys.id': pageId,
    content_type: 'page',
    include: 5,
  })
}

function PageDetails({ rootPage, pageId }) {
  const { siteName, newMenuItems } = usePageState()
  const [pageContent, setPageContent] = useState()

  const { status, data, error } = useQuery({
    queryKey: ['pageContent', pageId],
    queryFn: getPageContent,
    staleTime: 60 * 60 * 1000,
    }
  )

  useEffect(
    () => {
      //scroll to top of page on page load
      status === 'success' && setPageContent(data)
      document.documentElement.scrollTop = 0
      document.body.scrollTop = 0 // For Safari
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, status]
  )

  // same page slug can be present in multiple sites
  // hence find the page content from current site
  const currentPageContent =
    pageContent &&
    Array.isArray(pageContent.items) &&
    pageContent.items.find(
      item => item && item?.fields?.site?.fields?.slug === siteName
    )

  const subMenu = getSubMenu(newMenuItems, rootPage)

  return (
    <>
      {status === 'loading' && <LinerProgressBar siteName={siteName} />}
      {currentPageContent && (
        <IdentityBanner {...{ currentPageContent, siteName }} />
      )}
      {currentPageContent &&
        currentPageContent?.fields?.heroImage?.fields?.file?.url && (
          <header className="bg-body-darker">
            <div className="relative max-w-5xl mx-auto">
              <div
                className="bg-center bg-cover aspect-ratio-20/6"
                style={{
                  backgroundImage: `url(${
                    currentPageContent?.fields?.heroImage.fields?.file?.url
                  })`,
                }}
              />
              <div className="absolute flex items-center w-full h-full bg-black-transparent pin">
                <Container>
                  <div className="flex">
                    <div className="hidden w-20 mr-4">
                      <img
                        src={BannerIcon}
                        alt="Coronavirus latest"
                        className="p-0 m-0"
                      />
                    </div>
                    <div className="flex-1">
                      <span className="text-lg font-semibold text-white md:text-xl">
                        Coronavirus/Covid 19
                      </span>
                      <h1 className="text-2xl font-semibold text-white lg:text-5xl">
                        {currentPageContent?.fields.heroText}
                      </h1>
                    </div>
                  </div>
                </Container>
              </div>
            </div>
          </header>
        )}
      {subMenu &&
        subMenu.length > 0 && (
          <section className="sticky z-50 block w-full mb-6 -mt-6 border-b pin-t lg:hidden">
            <div className="relative bg-white">
              <MobileNavigation
                rootPage={rootPage}
                menuItems={subMenu}
                activePage={pageId}
              />
            </div>
          </section>
        )}
      {pageContent &&
        currentPageContent?.fields.intro && (
          <section className="mb-4 text-xl leading-normal text-white lg:mb-0">
            <Container>
              <div className="text-base leading-normal text-body lg:text-xl">
                {currentPageContent?.fields?.intro}
              </div>
            </Container>
          </section>
        )}
      <Container>
        <div className="mt-6 lg:mt-10 md:mt-8">
          <Page page={currentPageContent && currentPageContent?.fields} />
        </div>
      </Container>
      {error && <ErrorMessage />}
    </>
  )
}

export default PageDetails
