import React from 'react'
import { SearchFaqResult } from '../'
import { SubSection } from '../../utils'
import { SEARCH_LIMIT } from 'constant/common'

const SiteSearchFaqResults = ({ parsedSearchResults }) => {
  let faqsResults = (parsedSearchResults || []).filter(
    item => item.result.sys.contentType.sys.id === 'faqEntry'
  )
  faqsResults = faqsResults.slice(0, SEARCH_LIMIT)

  return faqsResults && faqsResults.length > 0 ? (
    <SubSection title="FAQs">
      <div className="max-w-xl">
          {faqsResults.map(faq => {
            return (
              <SearchFaqResult
                key={faq.result.sys.id}
                fields={faq.result.fields}
                faqContent={faq.result}
              />
            )
          })}
      </div>
    </SubSection>
  ) : null
}

export default SiteSearchFaqResults
