import React, { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { useLocation } from 'react-router-dom'
import logo from 'images/mymorri_logo.svg'
import { useUserState } from 'store/user-context';
import { UserAccountError, NotMorrisonsEmailError } from 'components/error'

const AccountErrorPage = () => {
  const { pathname } = useLocation()
  const userState = useUserState()
  console.log("userState : ", userState)

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: pathname, title: "Account Error" })
  }, [])

  return (
    <div
      id="error_page"
      className="flex flex-col items-center justify-center h-screen bg-green"
    >
      <div className="relative flex flex-col items-center justify-center p-4">
        <div className="h-32 mb-8">
          <img
            alt="Morrisons MyMorri"
            className="block w-auto h-full mx-auto"
            src={logo}
          />
        </div>
        {
          userState.error_user && userState.error_user.error ?
            <NotMorrisonsEmailError email={userState.error_user.email} />
            : <UserAccountError />
        }
      </div>
    </div>
  )
}

export default AccountErrorPage
