import React, { createContext, useReducer, useContext } from 'react'
import ReactGA from 'react-ga4'
import { HttpClient } from '../util'
import config from '../config'

const NewsStateContext = createContext()
const NewsDispatchContext = createContext()

const initialState = {
  isSideBarActive: false,
  isNewsBarActive: false,
  isSearchActive: false,
  selectedNewsId: null,
  contentful_category: [],
  selectedCategoryIndex: 0,
  notifications: {},
}

function newsReducer(state, action) {
  switch (action.type) {
    case 'TOGGLE_SIDEBAR':
      return { ...state, isSideBarActive: !state.isSideBarActive }
    case 'TOGGLE_SEARCH':
      return { ...state, isSearchActive: !state.isSearchActive }
    case 'HIDE_SEARCH':
      return { ...state, isSearchActive: false }
    case 'TOGGLE_NEWSBAR':
      return { ...state, isNewsBarActive: !state.isNewsBarActive }
    case 'SET_SELECTED_NEWSID':
      return { ...state, ...{ selectedNewsId: action.data } }
    case 'SAVE_CATEGORY_CONTENTFUL':
      return { ...state, contentful_category: [...action.data] }
    case 'SET_SELECTED_CATEGORY_INDEX':
      return { ...state, ...{ selectedCategoryIndex: action.data } }
    case 'ADD_TO_NOTIFICATION':
      return {
        ...state,
        notifications: { ...state.notifications, ...action.data },
      }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function NewsProvider({ children }) {
  const [state, dispatch] = useReducer(newsReducer, initialState)

  /////

  function toggleSideBar() {
    dispatch({ type: 'TOGGLE_SIDEBAR' })
  }

  function toggleSearchContainer() {
    dispatch({ type: 'TOGGLE_SEARCH' })
  }

  function hideSearchContainer() {
    dispatch({ type: 'HIDE_SEARCH' })
  }

  function toggleNewsBar() {
    dispatch({ type: 'TOGGLE_NEWSBAR' })
  }

  function setSelectedNews(data) {
    dispatch({ type: 'SET_SELECTED_NEWSID', data: data })
  }

  function saveContentCategories(data) {
    dispatch({ type: 'SAVE_CATEGORY_CONTENTFUL', data: data })
  }

  function addToNotification(data) {
    dispatch({ type: 'ADD_TO_NOTIFICATION', data: data })
  }

  function setSelectedCategoryIndex(data) {
    dispatch({ type: 'SET_SELECTED_CATEGORY_INDEX', data: data })
  }

  function setUnviewedNotifications(user) {
    const current_user = user
    if (current_user?.groups?.indexOf(config.payslips_group) !== -1) {
      HttpClient.get('/api/notifications/payslips/unviewed').then(data => {
        dispatch({
          type: 'ADD_TO_NOTIFICATION',
          data: {
            payslip: {
              label: 'payslip',
              labelPlural: 'payslips',
              count: data?.count,
            },
          },
        })
      })
    }
  }

  return (
    <NewsStateContext.Provider value={state}>
      <NewsDispatchContext.Provider
        value={{
          toggleSideBar,
          toggleSearchContainer,
          hideSearchContainer,
          toggleNewsBar,
          setSelectedNews,
          saveContentCategories,
          addToNotification, // not sure if this is required
          setSelectedCategoryIndex,
          setUnviewedNotifications,
        }}
      >
        {children}
      </NewsDispatchContext.Provider>
    </NewsStateContext.Provider>
  )
}

function useNewsState() {
  const context = useContext(NewsStateContext)
  if (context === undefined) {
    throw new Error('NewsState must be used within a NewsProvider')
  }
  return context
}

function useNewsDispatch() {
  const context = useContext(NewsDispatchContext)
  if (context === undefined) {
    throw new Error('NewsDispatch must be used within a NewsProvider')
  }
  return context
}

function triggerNotificationClick(notificationType, ifAnalyticsDone) {
  let text = ''
  switch (notificationType) {
    case 'payslip':
      HttpClient.http_delete('/api/notifications/payslips/unviewed').then(
        () => (window.location.href = config.epayslips_url)
      )
      text = 'My Payslip'
      break
    case 'activity':
      window.open(config.activityPlanner, '_blank')
      text = 'Activity Planner'
      break
    default:
      return
  }

  !ifAnalyticsDone && addToAnalytics(text)
}

function addToAnalytics(text) {
  ReactGA.event({
    category: 'Navigation',
    action: 'App Icon Click',
    label: text,
  })
}

export {
  NewsProvider,
  useNewsState,
  useNewsDispatch,
  triggerNotificationClick,
  addToAnalytics,
}
