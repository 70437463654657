import React from 'react'
import { tabTitles } from '../../../util/retail-utils';

const activeClass = 'active text-primary border-b-2 border-primary';
const nonActiveClass = 'text-black';

const MainNav = ({ onClick, tabDates, week }) => {
  let dateInMenu = false;
  return <div className="sticky z-20 bg-white shadow-md pin-t">
    <div className="container flex mx-auto md:px-8">
      <div className="flex w-full whitespace-no-wrap list-reset flex-nowrap handheld:scrolling-touch handheld:overflow-x-auto md:overflow-x-hidden">
          {tabDates.map((date, index) => {
            if (date === week) {
              dateInMenu = true;
            }
            return <div
              key={date}
              className={`${date === week ? activeClass : nonActiveClass} block px-3 hover:text-primary py-3 text-sm md:text-base text-left font-medium no-underline bg-white cursor-pointer menu-item flex-no-shrink xl:text-md router-link-exact-active router-link-active`}
              onClick={(e) => onClick(date)}
            >
              {tabTitles[index]}
            </div>
          })}
          <div
            className={`${dateInMenu ? nonActiveClass : activeClass} block px-3 hover:text-primary py-3 text-sm md:text-base text-left font-medium no-underline bg-white cursor-pointer menu-item flex-no-shrink xl:text-md router-link-exact-active router-link-active`}
            onClick={(e) => onClick("archive")}
          >
            Archive
          </div>
      </div>
    </div>
  </div>
}

export default MainNav
