import React from 'react'
import { HighlightText } from './'

const ParagraphFaq = ({ children }) => {
  return (
    <p className="mb-4 leading-normal faq-text">
      <HighlightText highlightChildren={children} />
    </p>
  )
}

export default ParagraphFaq
