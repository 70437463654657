import React from 'react'

const RightArrow = props => {
  return (
    <button
      className={props.classes}
      onClick={() => {
        props.handleClick('right')
      }}
    >
      <svg
        className="fill-green-light hover:fill-green"
        viewBox="0 0 9 14"
        width={props.width}
        height={props.height}
      >
        <path d="M1.7,0L9,7l-7.3,7L0,12.4L5.6,7L0,1.6L1.7,0z" />
      </svg>
    </button>
  )
}

export default RightArrow
