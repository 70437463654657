import React, { useState, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import NewsHeroCategory from './NewsHeroCategory'
import { Container, Section } from 'components/utils'
import { contentfulClient } from '../../util'

const TheLatest = props => {
    const [newsList, setNewList] = useState(null)
    const getLatestNewsContent = () => {
        const query = {
            skip: 0,
            limit: 1,
            order: '-fields.publishAt',
            content_type: 'article',
            'fields.featured': true,
            'fields.publishToHomePage': 'yes',
            'fields.publishAt[lte]': (new Date()).toISOString(),
        }

        return contentfulClient.getContentfulClient().getEntries(query)
    }

    const { data: newsListContent } = useQuery({
        queryKey: ['pageContent'],
        queryFn: getLatestNewsContent,
        staleTime: 60 * 60 * 1000,
    }
    )

    useEffect(
        () => {
            newsListContent && setNewList(newsListContent.items)
        },
        [newsListContent]
    )

    return (
        <Container hero>
            <Section title="Morrisons news" classes="hidden md:block lg:hidden">
                {newsList?.length > 0 &&
                    newsList.map((news, index) => (
                        <NewsHeroCategory key={index} data={news} />
                    ))}
            </Section>
        </Container>
    )
}

export default TheLatest
