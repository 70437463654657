import React from 'react'
import { SubSection } from '../../utils'
import { Document } from '../'
import { SEARCH_LIMIT } from 'constant/common'

const SiteSearchDocuments = ({ parsedSearchResults }) => {
  let documents = (parsedSearchResults || []).filter(
    item => item.result.sys.contentType.sys.id === 'googleDocument'
  )
  documents = documents.slice(0, SEARCH_LIMIT)

  return documents && documents.length > 0 ? (
    <SubSection title="Documents">
      <div className="max-w-xl">
        {documents.map(document => (
          <Document
            key={document.result.sys.id}
            fields={document.result.fields}
          />
        ))}
      </div>
    </SubSection>
  ) : null
}

export default SiteSearchDocuments
