import React from 'react'

const ErrorMessage = () => {
  return (
    <div className="container mx-auto p-4 md:p-8">
      <div className="flex text-base md:text-xl py-2">
        We're sorry, page isn't available at the moment. We'll get it up and
        running as soon as we can. Thanks for your patience.
      </div>
    </div>
  )
}
export default ErrorMessage
