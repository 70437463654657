import React, { createRef, useState } from 'react'
import { Link } from 'react-router-dom'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { usePageState } from 'store/page-context'
import { getPageTitle } from '../../util/sites'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const ActiveArrow = () => {
  return (
    <ChevronRightIcon
      size="small"
      sx={{ color: '#00683a', fontSize: '24px' }}
    />
  )
}

const MobileNavigation = ({ rootPage, menuItems, activePage }) => {
  const mobileNav = createRef()
  const [mobileNaveOpen, setMobileNaveOpen] = useState(false)
  const { path, siteName } = usePageState()

  const openMobileNavigation = () => {
    setMobileNaveOpen(!mobileNaveOpen)
  }

  const navigation = () => {
    const selectedMenuTitle = getPageTitle(menuItems, activePage)
    const subMenuClass = `${
      mobileNaveOpen
        ? 'h-screen overflow-x-hidden overflow-y-scroll border-t pb-10'
        : 'hidden'
    } absolute left-0 pin-x w-full bg-black-transparent shadow`
    return (
      <>
        <nav>
          <div
            className="flex items-center justify-between px-4 py-2 mx-auto cursor-pointer max-w-screen-tablet md:max-w-screen-desktop md:px-8 text-primary"
            onClick={openMobileNavigation}
          >
            <h2 className="relative z-50 w-11/12 my-2 text-sm font-semibold md:text-base text-primary">
              {selectedMenuTitle ? selectedMenuTitle : 'Pages in this section'}
            </h2>
            <span
              className={`${
                mobileNaveOpen
                  ? 'rotate-180 flex items-center'
                  : 'flex items-center'
              }`}
            >
              <ExpandMoreIcon sx={{ color: '#00683a', fontSize: '24px' }} />
            </span>
          </div>
          {menuItems.length > 0 ? (
            <div
              className={subMenuClass}
              ref={mobileNav}
              onClick={() => {
                setMobileNaveOpen(false)
              }}
            >
              {menuItems.map(subItem => navMenu(subItem, defaultUrl))}
            </div>
          ) : (
            <div className={subMenuClass} ref={mobileNav}>
              {navMenu(menuItems, defaultUrl)}
            </div>
          )}
        </nav>
      </>
    )
  }

  const shouldHideSubMenu = (item, parentLink) => {
    const pathExist = item && path && path.includes(item.href.url)
    return item && item?.children.length > 0 && !pathExist
  }

  const navMenu = (item, parentLink = '/sites', isSubMenu = false) => {
    let subMenuIcon = null
    let toUrl = ''
    const selectedItemStyle =
      'text-body-light justify-between items-center submenu-active'
    const defaultItemStyle = 'text-green-light'
    let mainNavItemColor = defaultItemStyle
    let subNavItemColor = isSubMenu ? 'text-body-light' : defaultItemStyle
    const hideSubMenu = shouldHideSubMenu(item, parentLink) ? 'hidden' : ''
    if (item.href) toUrl = item.href.url

    if (item && activePage === item.id) {
      mainNavItemColor = selectedItemStyle
      subNavItemColor = selectedItemStyle
    }
    if (item?.children.length > 0) {
      subMenuIcon = <ActiveArrow />
    }

    const menu =
      item && item.children.length > 0 ? (
        <div
          className={`${
            isSubMenu
              ? 'pl-6 bg-white'
              : 'border-t border-b fix-border border-body-lightest'
          }`}
          key={item.id}
        >
          <Link
            className={`${subNavItemColor} flex py-4 mx-auto font-medium no-underline text-sm md:text-base justify-between relative bg-white border-b`}
            data-id={item.id}
            to={toUrl}
            onClick={() => {
              setMobileNaveOpen(false)
            }}
          >
            <span className="flex items-center justify-between w-full px-4 mx-auto max-w-screen-tablet md:max-w-screen-desktop md:px-8">
              <span className="w-11/12"> {item.title}</span>
              <span className="flex items-end submenu-icon">{subMenuIcon}</span>
            </span>
          </Link>
          <div className={`${hideSubMenu}`}>
            {item.children.map(subItem => navMenu(subItem, toUrl, true))}
          </div>
        </div>
      ) : (
        item && (
          <div className={`${isSubMenu && 'pl-6'} bg-white`} key={item.id}>
            <Link
              className={`${mainNavItemColor} hover:bg-secondary-lighter flex px-4 py-4 mx-auto max-w-screen-tablet md:max-w-screen-desktop text-sm sm:text-base md:text-lg md:px-8 font-medium no-underline border-b border-body-lightest hover:text-secondary`}
              data-id={item.id}
              to={toUrl}
              onClick={() => {
                setMobileNaveOpen(false)
              }}
            >
              <span className="w-11/12"> {item.title}</span>
              <span>{subMenuIcon}</span>
            </Link>
          </div>
        )
      )
    return menu || null
  }

  const defaultUrl = `/sites/${siteName}/${rootPage}`

  return menuItems ? navigation() : null
}

export default MobileNavigation
