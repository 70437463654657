import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getParentPage } from 'util/sites'
import { useSitesState } from 'store/sites-context'

const LinkPage = ({ content }) => {
    const navigate = useNavigate()
  const { getPageUrl, setFetching } = useSitesState()

  const handleClick = async () => {
    setFetching(true)
    const pages = await getParentPage(content)
    const pageUrl = pages.length > 0 && getPageUrl(pages[0])
    setFetching(false)
    pageUrl && navigate(`${pageUrl}#${content.sys.id}`)
  }

  return (
    <div
      className="mt-3 mb-4 text-base font-light underline cursor-pointer lg:text-lg text-primary"
      onClick={handleClick}
    >
      Read the full article
    </div>
  )
}

export default LinkPage
