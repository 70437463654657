import React from 'react'

const Container = props => {
  return (
    <div
      className={
        props.hero
          ? 'container mx-auto md:px-8 handheld:px-0'
          : 'container mx-auto md:px-8 px-4'
      }
    >
      <div className={props.narrow && ''}>{props.children}</div>
    </div>
  )
}

export default Container
