import React from 'react'
// import ReactDOM from 'react-dom'
import ReactDOM from 'react-dom/client'
import morrisonsTheme from './morrisons_theme'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import AppRoute from './routes'
// import { ReactQueryDevtools } from 'react-query-devtools'
import { UserProvider } from 'store/user-context'
import { NewsProvider } from 'store/news-context'
import { SitesProvider } from 'store/sites-context'
import {
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'
import './morrisons.css'

import config from './config'
import ReactGA from 'react-ga4'

ReactGA.initialize(config.google_analytics_id)
const queryClient = new QueryClient()

let Root = () => {


    return (<QueryClientProvider client={queryClient}>
        <ThemeProvider theme={createTheme(morrisonsTheme)}>
            {/* <ReactQueryDevtools initialIsOpen={false} /> */}
            <UserProvider>
                <NewsProvider>
                    <SitesProvider>
                        <AppRoute />
                    </SitesProvider>
                </NewsProvider>
            </UserProvider>
        </ThemeProvider>
    </QueryClientProvider>
    )
}

ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <Root />
    </React.StrictMode>
)
