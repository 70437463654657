import React from 'react'
import { ErrorMessage } from './'

const ErrorBoundary = props => {
  try {
    return props.children
  } catch (e) {
    return <ErrorMessage />
  }
}

export default ErrorBoundary
