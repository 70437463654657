import React from 'react'
import { Link } from 'react-router-dom'

const MorrisonsLogo = props => {
  return (
    <Link
      to="/"
      onClick={props.clickEvent}
      className="block no-underline cursor-pointer"
      data-cy="logo-link-home"
    >
      <svg
        className="o-logo"
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="131px"
        height="58px"
        viewBox="0 0 131 58"
      >
        <path
          id="Fill-22"
          fill="#FEC10D"
          d="M63.7,18.3c0,1.1-0.9,1.9-1.9,1.9c-1.1,0-1.9-0.9-1.9-1.9c0-1.1,0.9-1.9,1.9-1.9
	S63.7,17.2,63.7,18.3"
        />
        <path
          id="Fill-24"
          fill="#FEC10D"
          d="M72.4,10.4c0,1.1-0.9,1.9-1.9,1.9c-1.1,0-1.9-0.9-1.9-1.9c0-1.1,0.9-1.9,1.9-1.9
	S72.4,9.3,72.4,10.4"
        />
        <path
          id="Fill-26"
          fill="#FEC10D"
          d="M67.8,4.5c0,2.6-2.3,4.1-2.3,4.1s-2.3-1.5-2.3-4.1s2.3-4.1,2.3-4.1S67.8,1.8,67.8,4.5"
        />
        <path
          id="Fill-28"
          fill="#FEC10D"
          d="M62.6,9.8c1.9,1.9,1.3,4.5,1.3,4.5s-2.7,0.6-4.6-1.3S58,8.5,58,8.5S60.7,7.9,62.6,9.8"
        />
        <path
          id="Fill-30"
          fill="#FEC10D"
          d="M54.9,12.9c2.3,1.3,2.4,4,2.4,4s-2.4,1.2-4.7-0.1s-2.4-4-2.4-4S52.6,11.6,54.9,12.9"
        />
        <path
          id="Fill-32"
          fill="#FEC10D"
          d="M58.4,21c2.5,0.9,3.1,3.6,3.1,3.6s-2.2,1.6-4.7,0.7s-3.1-3.6-3.1-3.6S55.9,20.1,58.4,21"
        />
        <path
          id="Fill-34"
          fill="#FEC10D"
          d="M70.4,18.9c-1.1,2.4-3.8,2.8-3.8,2.8s-1.5-2.3-0.3-4.7c1.1-2.4,3.8-2.8,3.8-2.8
	S71.5,16.5,70.4,18.9"
        />
        <path
          id="Fill-36"
          fill="#FEC10D"
          d="M78.5,16.9c-2.3,1.3-4.7,0.1-4.7,0.1s0.1-2.7,2.4-4s4.7-0.1,4.7-0.1S80.8,15.5,78.5,16.9"
        />
        <path
          id="Fill-38"
          fill="#FEC10D"
          d="M74.3,25.3c-2.5,0.9-4.7-0.7-4.7-0.7s0.6-2.6,3.1-3.6c2.5-0.9,4.7,0.7,4.7,0.7
	S76.8,24.4,74.3,25.3"
        />
        <path
          fill="#FFFFFF"
          d="M30.5,29.2c-4.7,0-8.2,3.4-8.2,7.8s3.5,7.8,8.3,7.8c4.7,0,8.2-3.4,8.2-7.8S35.2,29.2,30.5,29.2z M30.5,41
	c-2.2,0-3.8-1.7-3.8-4s1.6-4,3.8-4c2.1,0,3.8,1.7,3.8,4S32.7,41,30.5,41z"
        />
        <path
          id="Fill-42"
          fill="#FFFFFF"
          d="M67.4,44.5h-4c-0.1,0-0.2-0.1-0.2-0.2V29.8c0-0.1,0.1-0.2,0.2-0.2h4c0.1,0,0.2,0.1,0.2,0.2v14.5
	C67.6,44.4,67.5,44.5,67.4,44.5"
        />
        <path
          fill="#FFFFFF"
          d="M91.9,29.2c-4.7,0-8.2,3.4-8.2,7.8s3.5,7.8,8.3,7.8c4.7,0,8.2-3.4,8.2-7.8C100.2,32.6,96.6,29.2,91.9,29.2z
	 M92,41c-2.2,0-3.8-1.7-3.8-4s1.6-4,3.8-4c2.1,0,3.8,1.7,3.8,4C95.7,39.3,94.1,41,92,41z"
        />
        <path
          id="Fill-46"
          fill="#FFFFFF"
          d="M116.1,44.5h-4c-0.1,0-0.2-0.1-0.2-0.2v-8.6c0-1.8-0.5-2.4-1.8-2.4c-1.6,0-2.9,1-3.4,2.6
	c-0.2,0.5-0.2,1-0.2,1.7v6.7c0,0.1-0.1,0.2-0.2,0.2h-4c-0.1,0-0.2-0.1-0.2-0.2V29.8c0-0.1,0.1-0.2,0.2-0.2h3.9
	c0.1,0,0.2,0.1,0.2,0.2v1.5c0.8-0.9,2.3-2,4.6-2c3.6,0,5.4,1.9,5.4,5.8v9.3C116.3,44.4,116.3,44.5,116.1,44.5"
        />
        <path
          id="Fill-48"
          fill="#FFFFFF"
          d="M20.1,44.5h-4c-0.1,0-0.2-0.1-0.2-0.2c0-4.6-0.4-9.8-0.5-11.4c-1.4,1.9-2.8,4.5-3.9,7
	c0,0.1-0.1,0.1-0.2,0.1H9c-0.1,0-0.2,0-0.2-0.1c-1.1-2.5-2.4-5.1-3.9-7c-0.1,1.6-0.5,6.8-0.5,11.4c0,0.1-0.1,0.2-0.2,0.2h-4
	c-0.1,0-0.2-0.1-0.2-0.2c0-6.2,0.5-14.4,1.5-18.6c0-0.1,0.1-0.2,0.2-0.2h3.2c0.1,0,0.1,0,0.2,0.1c2.1,2.3,3.8,4.9,5.1,7.4
	c1.3-2.6,3-5.1,5.1-7.4c0,0,0.1-0.1,0.2-0.1h3.2c0.1,0,0.2,0.1,0.2,0.2c1,4.2,1.5,12.3,1.5,18.6C20.3,44.4,20.2,44.5,20.1,44.5"
        />
        <path
          id="Fill-51"
          fill="#FFFFFF"
          d="M45.1,44.5h-4c-0.1,0-0.2-0.1-0.2-0.2V29.8c0-0.1,0.1-0.2,0.2-0.2H45c0.1,0,0.2,0.1,0.2,0.2v1.8
	c1-1.4,2.5-2.2,4.1-2.2c0.2,0,0.4,0,0.6,0c0.1,0,0.2,0.1,0.2,0.2v3.9c0,0.1,0,0.1-0.1,0.1c0,0-0.1,0.1-0.2,0c-0.1,0-0.4,0-0.7,0
	c-1.3,0-2.8,0.7-3.5,2.7c-0.2,0.6-0.3,1.3-0.3,2.2v5.8C45.3,44.4,45.2,44.5,45.1,44.5"
        />
        <path
          id="Fill-53"
          fill="#FFFFFF"
          d="M56.3,44.5h-4c-0.1,0-0.2-0.1-0.2-0.2V29.8c0-0.1,0.1-0.2,0.2-0.2h3.9c0.1,0,0.2,0.1,0.2,0.2v1.8
	c1-1.4,2.5-2.2,4.1-2.2c0.2,0,0.4,0,0.6,0c0.1,0,0.2,0.1,0.2,0.2v3.9c0,0.1,0,0.1-0.1,0.1c0,0-0.1,0.1-0.2,0c-0.1,0-0.4,0-0.7,0
	c-1.3,0-2.8,0.7-3.5,2.7c-0.2,0.6-0.3,1.3-0.3,2.2v5.8C56.5,44.4,56.4,44.5,56.3,44.5"
        />
        <path
          id="Fill-55"
          fill="#FFFFFF"
          d="M67.8,25.6c0,1.3-1.1,2.3-2.4,2.3s-2.4-1-2.4-2.3s1.1-2.3,2.4-2.3C66.8,23.3,67.8,24.3,67.8,25.6"
        />
        <path
          id="Fill-57"
          fill="#FFFFFF"
          d="M77.3,35.1c-0.2,0-0.3-0.1-0.5-0.1c-1.5-0.5-2.4-0.8-2.4-1.5v-0.1c0-0.6,0.7-1,1.5-1
	c1,0,2,0.3,3.5,1.1l0,0h0.1c0.1,0,0.1,0,0.2-0.1l1.6-2.7v-0.1c0-0.1,0-0.1-0.1-0.2C79.5,29.5,77.8,29,76,29c-3.6,0-6,1.8-6,4.6v0.1
	c0,2.9,2.3,4,4.9,4.7c0.2,0.1,0.4,0.1,0.6,0.2c1.6,0.5,2.2,0.8,2.2,1.5v0.1c-0.1,0.6-0.6,0.9-1.7,0.9c-1.5,0-2.8-0.5-4.5-1.6l0,0
	h-0.1c-0.1,0-0.1,0-0.2,0.1l-1.8,2.7v0.1c0,0.1,0,0.1,0.1,0.2c2,1.4,3.9,2.2,6.2,2.2c4,0,6.4-1.7,6.4-4.8v-0.1
	C82.1,37.1,79.7,35.9,77.3,35.1"
        />
        <path
          id="Fill-59"
          fill="#FFFFFF"
          d="M126,35.2c-0.2-0.1-0.3-0.1-0.5-0.2c-1.5-0.5-2.4-0.8-2.4-1.5v-0.1c0-0.6,0.7-1,1.5-1
	c1,0,2,0.3,3.5,1.1l0,0h0.1c0.1,0,0.1,0,0.2-0.1l1.6-2.7v-0.1c0-0.1,0-0.1-0.1-0.2c-1.7-0.9-3.4-1.4-5.2-1.4c-3.6,0-6,1.8-6,4.6v0.1
	c0,2.9,2.3,4,4.9,4.7c0.2,0.1,0.4,0.1,0.6,0.2c1.6,0.5,2.2,0.8,2.2,1.5v0.1c-0.1,0.6-0.6,0.9-1.7,0.9c-1.5,0-2.8-0.5-4.5-1.6l0,0
	h-0.1c-0.1,0-0.1,0-0.2,0.1l-1.8,2.7v0.1c0,0.1,0,0.1,0.1,0.2c2,1.4,3.9,2.2,6.2,2.2c4,0,6.4-1.7,6.4-4.8v-0.1
	C130.8,37.1,128.4,35.9,126,35.2"
        />
        <g>
          <path
            fill="#FFFFFF"
            d="M41.6,49.6c0.8,0.7,1.9,2.6,2.3,3.6h0c0.4-1,1.5-2.9,2.2-3.6h1.3c0.2,1,0.6,5.1,0.6,7.7h-1.5
		c0-1.7-0.1-3.9-0.3-5.5h0c-0.5,0.7-1.6,2.8-1.8,3.7h-0.9c-0.3-0.9-1.3-3-1.9-3.7h0c-0.1,1.6-0.3,3.8-0.3,5.5h-1.5
		c0-2.6,0.3-6.7,0.6-7.7H41.6z"
          />
          <path
            fill="#FFFFFF"
            d="M48.7,49.6h1.6c0.5,1,1.2,2,2,3h0c0.8-1,1.5-2,2-3h1.5c-0.8,1.5-1.8,2.9-2.9,4.2v3.5h-1.5v-3.4
		C50.5,52.5,49.5,51.1,48.7,49.6z"
          />
          <path
            fill="#FFFFFF"
            d="M59.6,49.6c0.8,0.7,1.9,2.6,2.3,3.6h0c0.4-1,1.5-2.9,2.2-3.6h1.3c0.2,1,0.6,5.1,0.6,7.7h-1.5
		c0-1.7-0.1-3.9-0.3-5.5h0c-0.5,0.7-1.6,2.8-1.8,3.7h-0.9c-0.3-0.9-1.3-3-1.9-3.7h0c-0.1,1.6-0.3,3.8-0.3,5.5h-1.5
		c0-2.6,0.3-6.7,0.6-7.7H59.6z"
          />
          <path
            fill="#FFFFFF"
            d="M71,49.4c2.1,0,4.1,1.5,4.1,4c0,2.5-2,4-4.1,4s-4.1-1.5-4.1-4C66.9,51,68.9,49.4,71,49.4z M71,50.8
		c-1.3,0-2.6,1-2.6,2.7c0,1.7,1.3,2.7,2.6,2.7c1.3,0,2.6-1,2.6-2.7C73.6,51.8,72.3,50.8,71,50.8z"
          />
          <path
            fill="#FFFFFF"
            d="M78.8,49.6c1.2,0,2.4,0.8,2.4,2.2c0,0.9-0.6,1.7-1.3,2c0.8,0.8,1.6,2,2.3,3.5h-1.7c-0.5-1-1.3-2.3-2.3-3.2
		h-0.5v3.2h-1.5v-7.7H78.8z M79.7,51.9c0-0.7-0.6-1-1.1-1h-0.8v2h0.8C79.1,52.9,79.7,52.6,79.7,51.9z"
          />
          <path
            fill="#FFFFFF"
            d="M85.3,49.6c1.2,0,2.4,0.8,2.4,2.2c0,0.9-0.6,1.7-1.3,2c0.8,0.8,1.6,2,2.3,3.5H87c-0.5-1-1.3-2.3-2.3-3.2h-0.5
		v3.2h-1.5v-7.7H85.3z M86.2,51.9c0-0.7-0.6-1-1.1-1h-0.8v2h0.8C85.6,52.9,86.2,52.6,86.2,51.9z"
          />
          <path fill="#FFFFFF" d="M90.9,49.6v7.7h-1.5v-7.7H90.9z" />
        </g>
      </svg>
    </Link>
  )
}

export default MorrisonsLogo
