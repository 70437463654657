import React from 'react'
import { Document } from './'
const DocumentSection = ({ fields }) => {
  return fields ? (
    <section className="mt-10 mb-10">
      <h2 className="mb-6 text-lg font-semibold md:text-xl">{fields.title}</h2>
      {(fields.documents || []).map(document => {
        return (
          document?.fields && (
            <Document key={document?.sys?.id} fields={document.fields} />
          )
        )
      })}
    </section>
  ) : null
}

export default DocumentSection
