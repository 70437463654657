import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Faq } from './'

const FaqSection = ({ fields }) => {
  const [indices, setIndices] = useState([])

  const hashId = useLocation()
    .hash.split('#')
    .pop()

  useEffect(
    () => {
      const selectedFaqIndex = (fields?.refFAQs || []).findIndex(
        faq => faq?.sys?.id === hashId
      )
      if (selectedFaqIndex >= 0)
        setIndices([...indices, selectedFaqIndex].sort())
    },
    /* eslint-disable-next-line */
    [hashId]
  )

  return fields ? (
    <>
      <section className="max-w-xl mb-10">
        <h2 className="mb-6 text-lg font-semibold md:text-xl">
          {fields.title}
        </h2>
        {(fields.refFAQs || []).map((faq, index) => {
          return (
            <Faq key={faq.sys.id} faqId={faq.sys.id} fields={faq.fields} />
          )
        })}
      </section>
    </>
  ) : null
}

export default FaqSection
