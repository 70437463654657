import React, { useEffect } from 'react'
import ReactGA from 'react-ga4'
import logo from 'images/large_tree_logo.png'
import ios from 'images/badges/ios.png'
import android from 'images/badges/android.png'
import config from 'config'
import { useLocation } from 'react-router-dom'

const MyRota = () => {
  const { pathname } = useLocation()

  useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: pathname, title: "My Rota" })
    },[pathname])

  return (
    <div
      id="rota_page"
      style={{ textAlign: 'center' }}
      className="layout-column layout-align-center"
    >
      <div className="box">
        <div>
          <img alt="morrisons logo" src={logo} />
        </div>
        <div className="px-4 mb-6">
          <p className="welcome-text">
            You are about to access the My Rota, continue using:
          </p>
        </div>
        <div className="flex flex-wrap justify-center mb-4 md:mb-6">
          <a
            href="https://apps.apple.com/gb/app/ess-41-reflexis-one/id1473943383"
            className="w-48 mx-4 my-2 md:my-0"
          >
            <img alt="Get it on Apple Store" src={ios} />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.reflexisinc.dasess4110&hl=en"
            className="w-48 mx-4 my-2 md:my-0"
          >
            <img alt="Get it on Google Play" src={android} />
          </a>
        </div>
        <div className="flex justify-center px-4 mb-6">
          <a
            href={config.reflexisLink}
            className="text-primary hover:no-underline"
          >
            No thanks, continue using the website version
          </a>
        </div>
      </div>
    </div>
  )
}

export default MyRota
