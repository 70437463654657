import React from 'react'

const SubSection = props => {
  return (
    <section className="my-6 lg:my-10 md:my-8">
      {props.title && (
        <h2
          className={
            'text-lg md:text-xl font-medium font-semibold mb-4 md:mb-6'
          }
        >
          {props.title}
        </h2>
      )}
      {props.children}
    </section>
  )
}

export default SubSection
