import React from 'react'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

export default function Card({ title, onClickFunction, weekCommencing }) {
  return (
    <div
      onClick={onClickFunction}
      className="w-full px-2 pb-4 sm:w-1/2 lg:w-1/4"
    >
      <article className="card-link">
        <div className="card-link__wrapper archive_item">
          <h3 className="card-link__header">
            <span className="text-xl md:text-2xl">{title}</span>
          </h3>
          <div className="flex items-center justify-between pt-6 mt-0 border-t md:mt-2 card-link__more">
            <span>{weekCommencing}</span>
            <KeyboardArrowRightIcon/>
          </div>
        </div>
      </article>
    </div>
  )
}
