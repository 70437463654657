import React from 'react'
import { LinkEntry } from './'

const LinkSection = props => {
  const { fields } = props
  return fields ? (
    <section className="mt-10 mb-10">
      <h2 className="mb-6 text-lg font-semibold md:text-xl">{fields.title}</h2>
      <div className="flex flex-wrap -mx-2">
        {(fields.refLinks || []).map(link => {
          return <LinkEntry key={link.sys.id} refLink={link} />
        })}
      </div>
    </section>
  ) : null
}

export default LinkSection
