import React from 'react'

const Section = props => {
  return (
    <section>
      {props.title && (
        <h1
          className={
            'text-2xl md:text-2xl font-medium mt-8 mb-4 ' + props.classes
          }
        >
          {props.title}
        </h1>
      )}
      {props.children}
    </section>
  )
}

export default Section
