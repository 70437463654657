import React from 'react'

import { Container } from '../../utils'

const RetailCommsIdentityBanner = ({ date, title, week }) => {
  return (
    <header className="relative py-8 md:py-10 bg-gradient-retailcomms">
      <Container>
        <h1 className="text-3xl font-semibold text-white md:text-4xl lg:text-4xl">
          {title}
        </h1>
        {week && <span className="retail-comms-identity-week">Week {week} - {date}</span>}
      </Container>
      {/* <div className="absolute z-0 flex justify-end h-full overflow-hidden opacity-10 md:w-full pin-t pin-r pin-b">
      </div> */}
    </header>
  )
}

export default RetailCommsIdentityBanner
