import React, { createContext, useContext } from 'react'

const PageContext = createContext()

const PageContextProvider = ({ children, value }) => {
  return <PageContext.Provider value={value}>{children}</PageContext.Provider>
}

const usePageState = () => {
  return useContext(PageContext)
}

export { PageContextProvider, usePageState }
