import React, { useEffect, useState } from 'react'
import { getParentPage } from 'util/sites'
import { useSitesState } from 'store/sites-context'
import {
  SiteSearchDocuments,
  SiteSearchFaqResults,
  SiteSearchPageResults,
  SiteSearchYouTubeVideos,
} from './search'

const excludedContentTypesFromPageResult = [
  'faqEntry',
  'googleDocument',
  'youTubeEmbed',
  'documentSection',
]

const SiteSearchResults = ({ searchResults, onParseComplete, isLoading }) => {
  const [parsedSearchResults, setParsedSearchResults] = useState()
  const { navigation } = useSitesState()

  const isValidToFindPages = contentType => {
    return excludedContentTypesFromPageResult.indexOf(contentType) > -1
      ? false
      : true
  }

  useEffect(
    () => {
      async function parseResults() {
        const parsedResults = await Promise.all(
          Array.isArray(searchResults) &&
            searchResults.map(async result => {
              let pages
              if (isValidToFindPages(result?.sys?.contentType?.sys?.id))
                pages = await getParentPage(result)
              return {
                result,
                pages,
              }
            })
        )

        // remove duplicate pages from search results
        const finalResults = parsedResults.map(item => {
          return {
            ...item,
            pages:
              Array.isArray(item.pages) &&
              item.pages.filter(
                (page, index) =>
                  index ===
                  item.pages.findIndex(elem => elem?.sys?.id === page?.sys?.id)
              ),
          }
        })
        onParseComplete()
        setParsedSearchResults(finalResults)
      }
      searchResults && parseResults()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchResults]
  )

  return (
    <>
      {!isLoading && navigation && parsedSearchResults ? (
        <>
          <SiteSearchFaqResults {...{ parsedSearchResults }} />
          <SiteSearchPageResults
            {...{ parsedSearchResults, excludedContentTypesFromPageResult }}
          />
          <SiteSearchDocuments {...{ parsedSearchResults }} />
          <SiteSearchYouTubeVideos {...{ parsedSearchResults }} />
        </>
      ) : null}
    </>
  )
}

export default SiteSearchResults
