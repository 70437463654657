import config from 'config'

const contentfulApi = require('contentful')

class Contentful {
  createClient(user) {
    this.currentUser = user
    const headers =
      process.env.REACT_APP_ENV === 'local' ||
        config.contentfullApi.host === 'preview.contentful.com' ||
        config.contentfullApi.host === 'cdn.contentful.com'
        ? {}
        : { headers: { 'X-Token': this.currentUser.token } }
    const apiParams = { ...config.contentfullApi, ...headers }
    this.contentfulClient = contentfulApi.createClient(apiParams)
    // api para & contentful client for retail comms
    const retailCommsApiParams = {
      ...config.retailCommsContentfulApi,
      ...headers,
    }
    this.retailCommsContentfulClient = contentfulApi.createClient(
      retailCommsApiParams
    )

    // api para & contentful client for retail comms
    const convenienceApiParams = {
      ...config.convenienceContentfulApi,
      ...headers,
    }
    this.convenienceContentfulClient = contentfulApi.createClient(
      convenienceApiParams
    )
  }

  getCurrentUser() {
    return this.currentUser
  }

  getContentfulClient() {
    return this.contentfulClient
  }

  getReatilCommsContentfulClient() {
    return this.retailCommsContentfulClient
  }

  getConvenienceContentfulClient() {
    return this.convenienceContentfulClient
  }
}

export default new Contentful()
