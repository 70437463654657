import React from 'react'
import { useNavigate } from 'react-router-dom'
import { DateWidget } from 'components/utils'
import get from 'lodash/get'
import { useIsMobile } from '../../util'
import { useNewsDispatch } from 'store/news-context'
import { Remarkable } from '../../util'

Remarkable.configure({
  html: true,
  linkTarget: '_blank',
})

const NewsHeroCategory = props => {
  const [isMobile] = useIsMobile()
  const { setSelectedNews } = useNewsDispatch()
  const navigate = useNavigate()

  const handleClick = id => {
    setSelectedNews(id)
    navigate('/news/' + props.data.sys.id)
  }

  const imageUrl = get(props.data.fields, 'imageTeaser.fields.file.url', '')

  const scaleImage = isMobile
    ? imageUrl + '?fit=thumb&w=500&h=281'
    : imageUrl + '?fit=thumb&w=1000&h=563'
  return (
    <article
      data-cy="featured"
      id="featured"
      className="relative md:mt-8 md:mb-6 lg:flex lg:flex-row-reverse lg:border-b lg:pb-8 lg:mb-0"
    >
      <div className="lg:w-1/2 lg:pl-12">
        <div className="relative overflow-hidden aspect-ratio-16/9 md:rounded-lg">
          <img
            src={scaleImage}
            alt="news"
            className="absolute block w-full cursor-pointer pin-x pin-y"
            onClick={() => handleClick(props.data.sys.id)}
          />
        </div>
      </div>
      <div className="relative z-10 py-6 bg-white handheld:px-4 lg:py-0 lg:w-1/2">
        <div className="lg:pr-10">
          <h1
            className="mb-3 text-2xl font-medium cursor-pointer xl:text-4xl hover:text-primary"
            onClick={() => handleClick(props.data.sys.id)}
          >
            {props.data.fields.title}
          </h1>
          <DateWidget
            className="block font-medium cursor-pointer text-green-light lg:mb-2 hover:text-primary"
            format="MMMM Do YYYY"
            value={props.data.fields.publishAt}
            onClick={() => handleClick(props.data.sys.id)}
          />
          <div
            className="c-wysiwyg xl:text-lg lg:leading-normal hover:no-underline"
            dangerouslySetInnerHTML={{
              __html: Remarkable.getHTML(props.data.fields.summary),
            }}
          />
        </div>
      </div>
    </article>
  )
}

export default NewsHeroCategory
