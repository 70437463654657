import React from 'react'
import DescriptionIcon from '@mui/icons-material/Description'
import { HighlightText } from './'

const Document = ({ fields }) => {
  const { title, link } = fields
  return (
    <>
      <a
        className="flex items-center justify-between w-full max-w-xl pl-8 text-base font-semibold no-underline border border-l-4 cursor-pointer fix-border border-body-lightest text-body-light hover:text-primary"
        onClick={() => window.open(link, '_blank')}
      >
        <div className="flex flex-col">
          <span className="block mb-1 text-xs">View Document</span>
          <span className="text-lg">
            <HighlightText highlightChildren={title} />
          </span>
        </div>
        <div className="px-6 py-6 bg-secondary-lightest border-body-lightest">
          <DescriptionIcon size="small" sx={{ color: '#697783' }} />
        </div>
      </a>
    </>
  )
}

export default Document
