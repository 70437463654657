import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Icon } from 'components/utils'

const Search = props => {
  const [searchValue, setsearchValue] = useState('')
  const [error, setError] = useState(false)
  const searchRef = React.createRef()
  const navigate = useNavigate()

  useEffect(
    () => {
      setsearchValue(props.value || '')
    },
    [props.value]
  )

  const moveCursorAtEnd = e => {
    var temp_value = e.target.value
    e.target.value = ''
    e.target.value = temp_value
  }

  const handleChange = event => {
    setError(false)
    setsearchValue(event.target.value)
  }

  const handleOnSearch = () => {
    searchValue.trim().length > 2
      ? navigate('/search?q=' + searchValue)
      : setError(true)
  }

  const handleKeyPress = event => {
    event.key === 'Enter' && handleOnSearch()
  }

  return (
    <div className="py-4 xl:py-6 handheld:px-1">
      {error && (
        <div className="pl-4 mb-1 text-sm leading-tight text-primary-dark">
          Please enter a minimum of <span className="font-semibold">3</span>{' '}
          characters to search:
        </div>
      )}
      <div className="flex handheld:px-1">
        <input
          type="text"
          className={`${
            error ? 'text-tertiary' : ''
          } p-4 lg:p-6 text-md w-full rounded-bl-lg rounded-tl-lg shadow-md md:text-xl focus:outline-none border border-white focus:border-grey`}
          value={searchValue}
          placeholder={props.placeholder}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          onFocus={moveCursorAtEnd}
          ref={searchRef}
        />
        <button
          className="p-4 font-thin text-white rounded-tr-lg rounded-br-lg shadow-md bg-green-light focus:bg-green text-md md:text-xl md:pl-10 md:pr-10 lg:pl-15 lg:pr-15"
          onClick={handleOnSearch}
        >
          <Icon icon="search" size="28" />
        </button>
      </div>
    </div>
  )
}

export default Search
