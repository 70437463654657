import React from 'react'
import { DateWidget } from 'components/utils'

const ArticleTitle = props => {
  return (
    <div>
      <h1 className="mb-3 text-2xl font-semibold md:text-3xl xl:text-4xl">
        {props?.title}
      </h1>
      <span className="block mb-6 text-black">
        <DateWidget
          className="text-green-light"
          format="MMMM Do YYYY"
          value={props?.date}
        />
        <span>{props?.category && ` / ${props?.category}`}</span>
      </span>
    </div>
  )
}

export default ArticleTitle
