import React from 'react'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'

import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import {
  Document,
  DocumentSection,
  EntryHyperlink,
  FaqSection,
  Heading2,
  LinkSection,
  NewsSection,
  NoticeSection,
  Paragraph,
  Strong,
  YouTubeSection
} from './'

const RichtTextReact = ({ richText, overrides }) => {
  return documentToReactComponents(richText, overrides)
}

const RichTextSection = ({ className = "", richText }) => {
  return (<section className={`c-wysiwyg ${className}`}>
    {richText && (
      <RichtTextReact
        richText={richText}
        overrides={
          {
            renderMark: {
              [BLOCKS.HEADING_1]: {
                component: Heading2,
              },
              [INLINES.ENTRY_HYPERLINK]: {
                page: EntryHyperlink,
              },
              [BLOCKS.PARAGRAPH]: {
                component: Paragraph,
              },
              [BLOCKS.HEADING_2]: {
                component: Heading2,
              },
            }, renderNode: {
              [BLOCKS.EMBEDDED_ENTRY]: (node) => {
                const embeddedComponentId = node?.data?.target?.sys?.contentType?.sys?.id
                if (embeddedComponentId === "faqSection") {
                  return <FaqSection fields={node.data.target.fields} />
                }
                if (embeddedComponentId === "articleSection") {
                  return <NewsSection fields={node.data.target.fields} />
                }
                if (embeddedComponentId === "documentSection") {
                  return <DocumentSection fields={node.data.target.fields} />
                }
                if (embeddedComponentId === "linkSection") {
                  return <LinkSection fields={node.data.target.fields} />
                }
                if (embeddedComponentId === "youTubeSection") {
                  return <YouTubeSection fields={node.data.target.fields} />
                }
                if (embeddedComponentId === "notice") {
                  return <NoticeSection fields={node.data.target.fields} open={node?.data?.target?.open} />
                }
                if (embeddedComponentId === "googleDocument") {
                  return <Document fields={node.data.target.fields} />
                }
              },
              strong: {
                component: Strong,
              },
            }
          }}
      />
    )}
  </section>)
}

export default RichTextSection
