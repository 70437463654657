import React, { createContext, useContext, useState, useEffect } from 'react'
import { useUserState } from './user-context'
import { contentfulClient, Core } from '../util'
import { getParseData } from 'util/sitetree'

const SitesContext = createContext()

const SitesProvider = ({ children }) => {
  const [navigation, setNavigation] = useState()
  const [storeNavigation, setStoreNavigation] = useState()
  const [fetching, setFetching] = useState(false)
  const [highlightString, setHighlightString] = useState('')
  const [siteError, setSiteError] = useState(null)
  const [siteUrls, setSiteUrls] = useState([])
  const [pageNavigation, setPageNavigation] = useState([])
  const [siteMap, setSiteMap] = useState([])
  const userState = useUserState()
  const { current_user } = userState
  const { redirectOnError } = Core

  const getPageUrl = page => {
    return siteUrls[(page?.sys?.id)]
  }

  const getSites = () => {
    const topSites = []
    if (navigation && Array.isArray(navigation.items)) {
      navigation.items.forEach(item => {
        topSites.push({
          title: item?.fields?.title,
          href: { slug: item?.fields?.slug, url: item?.fields?.slug },
          id: item?.sys?.id,
        })
      })
    }

    //loading store bulletins navigation
    if (storeNavigation && Array.isArray(storeNavigation.items)) {
      storeNavigation.items.forEach(item => {
        topSites.push({
          title: item?.fields?.title,
          href: { slug: item?.fields?.slug, url: item?.fields?.slug },
          id: item?.sys?.id,
        })
      })
    }

    return topSites
  }

  useEffect(
    () => {
      if (current_user && current_user.email) {
        contentfulClient
          .getContentfulClient()
          .getEntries({ content_type: 'mainNavigation', include: 0 })
          .then(entries => {
            setNavigation(entries)
          })
          .catch(error => {
            redirectOnError(error)
          })

        contentfulClient
          .getContentfulClient()
          .getEntries({
            content_type: 'page',
            order: 'fields.title',
            select:
              'fields.title,fields.parentID,fields.slug,fields.site,fields.order',
          })
          .then(entries => {
            const { parseData, pageSiteMap, siteUrl } = getParseData(
              entries.items
            )
            setSiteMap(pageSiteMap)
            setPageNavigation(parseData)
            setSiteUrls(siteUrl)
            if (pageSiteMap.length === 0 && siteUrl.length === 0) {
              setSiteError(true)
            }
          })
          .catch(error => {
            redirectOnError(error)
          })

        // retail comms main nav data
        contentfulClient
          .getReatilCommsContentfulClient()
          .getEntries({ content_type: 'mainNavigation', include: 0 })
          .then(entries => {
            setStoreNavigation(entries)
          })
          .catch(error => {
            redirectOnError(error)
          })

          // convenience main nav data
        contentfulClient
        .getConvenienceContentfulClient()
        .getEntries({ content_type: 'mainNavigation', include: 0 })
        .then(entries => {
          console.log('new api : ', entries)
        })
        .catch(error => {
          redirectOnError(error)
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [current_user]
  )

  return (
    <SitesContext.Provider
      value={{
        navigation,
        fetching,
        setFetching,
        highlightString,
        setHighlightString,
        pageNavigation,
        siteMap,
        getSites,
        siteError,
        setSiteError,
        getPageUrl,
      }}
    >
      {children}
    </SitesContext.Provider>
  )
}

const useSitesState = () => useContext(SitesContext)

export { SitesProvider, useSitesState }
