import React, { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { TheHeader, HeaderTab } from 'components/header'
import { TheArticle } from 'components/news'
import { ErrorBoundary } from 'components/error'
import { contentfulClient, useSetState } from '../util'
import { useLocation, useParams } from 'react-router-dom'
import { SuggestedNews } from 'components/news'
import { useNewsState } from 'store/news-context'
import { LinerProgressBar } from 'components/sites/LinerProgressBar'

const NewsDetails = () => {
  const { id } = useParams()

  const defaultState = {
    newsContent: undefined,
    isLoading: false,
    delay: 500,
    suggestedNews: undefined,
  }
  const [state, setState] = useSetState(defaultState)
  const newsState = useNewsState()
  let { pathname } = useLocation()

  const fetchSuggestedNews = category => {
    const query = {
      limit: 20,
      order: '-fields.publishAt',
      content_type: 'article',
      'fields.category.sys.id[in]': category,
      'fields.publishAt[lte]': (new Date()).toISOString(),
    }
    contentfulClient
      .getContentfulClient()
      .getEntries(query)
      .then(({ items }) =>
        setState({
          suggestedNews: items,
        })
      )
      .catch(() =>
        setState({
          suggestedNews: [],
        })
      )
  }

  const fetchNewsDetails = newsId => {
    const query = {
      'sys.id': newsId,
    }
    try {
      contentfulClient
        .getContentfulClient()
        .getEntries(query)
        .then(({ items }) => {
          setState({
            newsContent: items[0],
            isLoading: false,
          })
          fetchSuggestedNews(items[0].fields.category[0].sys.id)
        })
        .catch(error => {
          setState({
            isLoading: false,
          })
          if (error && error.response && error.response.status === 403) {
            window.location = '/timeout'
            return
          }
        })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(
    () => {
      setState({
        isLoading: true,
      })
      fetchNewsDetails(id)
      ReactGA.send({ hitType: "pageview", page: pathname, title: "News Details" })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id]
  )

  const { isLoading, newsContent, suggestedNews } = state

  return (
    <div>
      <HeaderTab activeTab="mymorri" />
      <TheHeader isArticle="true" />
      {isLoading && <LinerProgressBar siteName="" />}
      {!isLoading &&
        newsContent && (
          <>
            <ErrorBoundary>
              <TheArticle data={newsContent} />
              <SuggestedNews
                newsItems={suggestedNews}
                currentNewsId={newsState.selectedNewsId}
              />
            </ErrorBoundary>
          </>
        )}
    </div>
  )
}

export default NewsDetails
