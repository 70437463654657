import { createTheme  } from '@mui/material/styles'

export const defaultTheme = createTheme ({
  palette: {
    primary: {
      light: '#7fc49f',
      main: '#007435',
      dark: '#164e38',
    },
  },
})

export const covidVirusTheme = createTheme({
  palette: {
    primary: {
      light: '#357cc1',
      main: '#357cc1',
      dark: '#1478d8',
    },
  },
})

export const wellbeingTheme = createTheme({
  palette: {
    primary: {
      light: '#ab806e',
      main: '#a7674c',
      dark: '#530b42',
    },
  },
})
