import React, { useState } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Icon } from 'components/utils'
import Banner from 'assets/icons/svg/new-banner.svg'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useUserState } from 'store/user-context'
import config from 'config'

const isNewIcon = date => {
  return date ? moment(date, ["MM/DD/YYYY"]).add(6, 'weeks') >= moment() : false
}

const isExternal = url => /^(http|https):\/\//gm.test(url)

const tileClass =
  'relative flex w-1/3 px-1 sm:w-1/5 md:w-1/6 lg:w-1/8 lg:px-2 lg:mb-0'

const Tile = ({
  handleClick,
  icon,
  id,
  menu,
  newFromDate,
  notification,
  on_click,
  openInNewWindow,
  svg,
  text,
  url,
}) => {
  const [menuAnchor, setMenuAnchor] = useState(null)


  const userState = useUserState()
  const { current_user } = userState
  const groupToEmail = group => group + '@' + config.email_domain
  // eslint-disable-next-line
  const isInGroup = groups =>
    Array.isArray(groups) && groups.some(r => current_user?.groups?.indexOf(groupToEmail(r)) >= 0)

  const tileContents = (
    <>
      {notification && (
        <span className="absolute z-20 flex items-center justify-center w-6 h-6 font-mono text-xs leading-none text-white rounded-full bg-green-light pin-t pin-r md:w-8 md:h-8">
          {notification}
        </span>
      )}
      <div className="w-full">
        <div className="p-2">
          <div
            className={`${id === 'fb_icon' ? 'bg-facebook ' : 'bg-gradient-green-45deg '
              }shadow-md h-12 aspect-ratio-square rounded rounded-xl relative cursor-pointer overflow-hidden`}
          >
            {isNewIcon(newFromDate) && (
              <img
                src={Banner}
                alt="icon"
                className="absolute w-full h-full pin-y"
              />
            )}
            <div className="absolute flex flex-col items-center content-between justify-center morri-tile pin-y pin-x text-orange">
              <Icon icon={icon} svg={svg} size="48" color="text-orange" />
            </div>
          </div>
        </div>
        <span className="block px-2 py-2 font-light leading-tight text-center text-black lg:py-4 mob:text-sm">
          {text}
        </span>
      </div>
    </>
  )

  if (menu) {
    return (
      <>
        <div
          id={id}
          data-cy={id}
          className={tileClass}
          onClick={e => setMenuAnchor(e.currentTarget)}
        >
          {tileContents}
          <div
            className="absolute cursor-pointer"
            style={{ top: '10px', right: '20px' }}
            id="tile_menu_dot"
            data-cy="tile_menu_dot"
          >
            <MoreHorizIcon size="small" sx={{ color: '#ffffff' }} />
          </div>
        </div>
        <Menu
          id="simple-menu"
          data-cy="tile_menu_dot-item"
          anchorEl={menuAnchor}
          keepMounted
          open={menuAnchor !== null}
          onClose={e => setMenuAnchor(null)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {menu.map((item, index) => {
            {

              return isInGroup(item.groups) || !item.groups ?

                <MenuItem
                  onClick={e => setMenuAnchor(null)}
                  className="flex items-center  text-left p-0"
                  key={index}
                >
                  {isExternal(item.url) ? (
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="w-full text-sm text-black no-underline on_click hover:text-primary-dark "
                      onClick={e => handleClick(e, item, item.on_click)}
                    >
                      <span className="w-full inline-flex h-10 items-center px-3">
                        {item.text}
                      </span>
                    </a>
                  ) : (
                    <Link
                      to={item.url}
                      className="w-full text-sm text-black no-underline"
                      onClick={e => handleClick(e, item, item.on_click)}
                    >
                      <span className="w-full inline-flex h-10 items-center px-3">
                        {item.text}
                      </span>
                    </Link>
                  )}
                </MenuItem>
                : null
            }
          })}
        </Menu>
      </>
    )
  }

  if (isExternal(url)) {
    return (
      <div id={id} data-cy={id} className={tileClass}>
        <a
          href={url}
          target={openInNewWindow ? '_blank' : '_self'}
          className="w-full no-underline"
          onClick={e => handleClick(e, { url: url, text: text }, on_click)}
        >
          {tileContents}
        </a>
      </div>
    )
  }

  return (
    <div
      id={id}
      data-cy={id}
      className="relative flex w-1/3 px-1 sm:w-1/5 md:w-1/6 lg:w-1/8 lg:px-2 lg:mb-0"
    >
      <Link
        to={url}
        className="w-full no-underline"
        onClick={e => handleClick(e, { url: url, text: text }, on_click)}
      >
        {tileContents}
      </Link>
    </div>
  )
}

export default Tile
