import React from 'react'

const Avatar = props => {
  return (
    <figure className="mr-4" data-test="Profile Image">
      <img
        alt="Avatar"
        width={props.size}
        height={props.size}
        src={props.path}
        className="rounded-full"
      />
    </figure>
  )
}

export default Avatar
