import React from 'react'
import ArticleTitle from './ArticleTitle'
import ArticleBanner from './ArticleBanner'
import ArticleContent from './ArticleContent'
import { Container } from 'components/utils'

const TheArticle = props => {
  const scaleImage =
    props.data.fields?.imageTeaser?.fields?.file?.url +
    '?fit=thumb&w=1000&h=563'
  return props.data && props.data.fields ? (
    <article>
      <div className="bg-green-lightest">
        <Container narrow>
          <div className="items-center py-6 md:flex md:py-10">
            <div className="md:w-1/2 md:pr-10">
              <ArticleTitle
                date={props?.data?.fields?.publishAt}
                title={props?.data?.fields?.title}
                category={
                  props?.data?.fields?.category
                    ? props?.data?.fields?.category[0]?.fields?.name
                    : ''
                }
              />
            </div>
            <div className="md:w-1/2">
              <ArticleBanner
                src={scaleImage}
                alt={props?.data?.fields?.title}
              />
            </div>
          </div>
        </Container>
      </div>
      <Container narrow>
        <ArticleContent content={props?.data?.fields?.content} />
      </Container>
    </article>
  ) : null
}

export default TheArticle
